import * as Plot from "@observablehq/plot";
import React, { useEffect, useRef } from "react";

import nation from "./nation.json";
import statemesh from "./statemesh.json";
import states from "./states.json";

function getActiveRegions(stats) {
  const activeRegions = stats
    .filter(
      (stat) =>
        stat.TotalCasinoBetsCount !== 0 ||
        stat.TotalCasinoGrossBalance !== 0 ||
        stat.TotalCasinoTurnOver !== 0 ||
        stat.TotalSportsBetBetsCount !== 0 ||
        stat.TotalSportsBetBonusBetsCount !== 0 ||
        stat.TotalSportsBetBonusGrossBalance !== 0 ||
        stat.TotalSportsBetBonusTurnOver !== 0 ||
        stat.TotalSportsBetGrossBalance !== 0 ||
        stat.TotalSportsBetTurnOver !== 0
    )
    .map((stat) => stat.Region);

  return activeRegions;
}

function filterActiveStates(activeRegions) {
  const activeStateCodes = activeRegions.map((region) => region.State);
  const allStates = states;

  const activeStates = allStates.filter((state) =>
    activeStateCodes.includes(state.properties.code)
  );

  return activeStates;
}

export function PlayerRegionGraph({ playerStatsAggregation }) {
  const activeRegions = getActiveRegions(playerStatsAggregation);
  const activeStates = filterActiveStates(activeRegions);

  const graphDivRef = useRef();

  useEffect(() => {
    const plot = Plot.plot({
      projection: "albers-usa",
      format: {},
      marks: [
        Plot.geo(nation),
        Plot.geo(statemesh, { strokeOpacity: 0.2 }),
        Plot.geo(activeStates, {
          fill: (d) => d.properties.name,
          stroke: "black",
        }),
        Plot.tip(
          activeStates,
          Plot.pointer(
            Plot.centroid({
              title: (d) => d.properties.name,
              fontSize: 15,
            })
          )
        ),
      ],
    });

    graphDivRef.current.append(plot);

    // eslint-disable-next-line consistent-return
    return () => plot.remove();
  }, [playerStatsAggregation]);

  return <div ref={graphDivRef} />;
}
