import React, { useRef } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { forceChangePassword } from "../../_services";

export default function ChangePasswordModal(props) {
  const { loading, onHide } = props;
  const {
    register,
    watch,
    formState: { errors },
  } = useForm();
  const newPassword = useRef("");
  newPassword.current = watch("newPassword", "");

  const email = useSelector((state) => state.user.user.email);
  const challenge = useSelector((state) => state.user.user.challenge);

  const setNewPassword = async (e) => {
    e.preventDefault();
    await forceChangePassword({
      email,
      newPassword: newPassword.current,
      challenge,
    });
    onHide();
  };

  return (
    <Modal {...props}>
      <Modal.Header>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <Modal.Title>Please set your new password</Modal.Title>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => setNewPassword(e)}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>New password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter your password"
              name="newPassword"
              {...register("newPassword", {
                required: "Password is required",
                pattern: {
                  value:
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
                  message:
                    "Password should be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one digit and one special character",
                },
              })}
              // onChange={(e) => handleChange(e)}
              required
              autoFocus
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <h6>Confirm Password</h6>
            <Form.Control
              type="password"
              placeholder="Confirm your password"
              name="confirmPassword"
              {...register("confirmPassword", {
                required: "Confirm password is required",
                validate: (value) =>
                  value === newPassword.current || "Passwords do not match",
              })}
            />
            <p style={{ color: "rgb(206, 81, 81)" }}>
              {errors.confirmPassword?.message}
            </p>
          </Form.Group>
          <Modal.Footer>
            <Button type="submit" className="regBtn" variant="primary">
              {loading ? (
                <Spinner animation="border" size="sm" variant="light" />
              ) : (
                "Submit"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
