import "./riskAccounts.css";

import { React, useEffect, useState } from "react";
import { Card, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import { RiskAccountsTable } from "../../_components";

export function AsideRight(props) {
  const { handleIsActive } = props;
  const handleActive = (bool) => {
    handleIsActive(bool);
  };

  const location = useLocation();
  const navigate = useNavigate();

  const [isRiskLevelHigh, setIsRiskLevelHigh] = useState(false);
  const [isRiskLevelMedium, setIsRiskLevelMedium] = useState(false);
  const [isRiskLevelLow, setIsRiskLevelLow] = useState(false);

  const riskLevel = determineRiskLevel();

  function determineRiskLevel() {
    const level = [];
    if (isRiskLevelHigh) level.push("high");
    if (isRiskLevelMedium) level.push("medium");
    if (isRiskLevelLow) level.push("low");

    return level;
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const riskLevelParam = params.getAll("riskLevel");
    const isRiskLevelHighParam = riskLevelParam.includes("high");
    const isRiskLevelMediumParam = riskLevelParam.includes("medium");
    const isRiskLevelLowParam = riskLevelParam.includes("low");

    // Set values
    setIsRiskLevelHigh(isRiskLevelHighParam);
    setIsRiskLevelMedium(isRiskLevelMediumParam);
    setIsRiskLevelLow(isRiskLevelLowParam);
  }, [window.location.search]);

  const handleRiskLevelToggleChange = (setLevel, level, event) => {
    const isChecked = event.target.checked;

    // Set risk level value
    setLevel(isChecked);

    // Update params
    const params = new URLSearchParams(window.location.search);

    // Get all riskLevel values and filter out the selected level
    const levels = params.getAll("riskLevel").filter((lvl) => lvl !== level);

    if (isChecked) {
      levels.push(level); // Add the selected risk level if checked
    }

    // Set the updated riskLevel parameter
    params.delete("riskLevel");
    levels.forEach((lvl) => params.append("riskLevel", lvl));

    navigate(`?${params.toString()}`);
  };

  const handleHighRiskLevelToggleChange = (event) =>
    handleRiskLevelToggleChange(setIsRiskLevelHigh, "high", event);

  const handleMediumRiskLevelToggleChange = (event) =>
    handleRiskLevelToggleChange(setIsRiskLevelMedium, "medium", event);

  const handleLowRiskLevelToggleChange = (event) =>
    handleRiskLevelToggleChange(setIsRiskLevelLow, "low", event);

  return (
    <div className="asideRight">
      <div className="title">
        <h2>Risk Players</h2>
      </div>

      <Row>
        <Form.Group className="d-flex align-items-center">
          <Form.Label>Risk Level High (score 10-15) &nbsp;&nbsp;</Form.Label>
          <Form.Check
            type="switch"
            id="high-risk-switch"
            label=""
            checked={isRiskLevelHigh}
            onChange={handleHighRiskLevelToggleChange}
          />
          <Form.Label>&nbsp;&nbsp;Medium (5-9) &nbsp;&nbsp;</Form.Label>
          <Form.Check
            type="switch"
            id="medium-risk-switch"
            label=""
            checked={isRiskLevelMedium}
            onChange={handleMediumRiskLevelToggleChange}
          />
          <Form.Label>&nbsp;&nbsp;Low (1-4)&nbsp;&nbsp;</Form.Label>
          <Form.Check
            type="switch"
            id="low-risk-switch"
            label=""
            checked={isRiskLevelLow}
            onChange={handleLowRiskLevelToggleChange}
          />
        </Form.Group>
      </Row>

      <Row className="d-flex row">
        <Card className="bigCard">
          <div className="col md-2" />
          <div className="col col-md-6" />
          <div className="mt-2 mb-3">
            <Card className="mb-3">
              <RiskAccountsTable riskLevel={riskLevel} />
            </Card>
          </div>
        </Card>
      </Row>
    </div>
  );
}
