import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";

import { PlayerRegionGraph, ReportCard } from "../../_components";
import { OrgRegionContext } from "../../_contexts";
import { toCurrency, toHumanNumber } from "../../_helpers";

export function findStatisticsByOrgAndRegion(statistics, orgID, regionID) {
  return statistics.find(
    (statistic) =>
      statistic.Org.ID === orgID && statistic.Region.ID === regionID
  );
}

export function sumStats(statistics) {
  const total = {
    TotalSportsBetBetsCount: 0,
    TotalSportsBetBonusBetsCount: 0,
    TotalSportsBetBonusGrossBalance: 0,
    TotalSportsBetBonusTurnOver: 0,
    TotalSportsBetGrossBalance: 0,
    TotalSportsBetTurnOver: 0,
    TotalDeposit: 0,
    TotalWithdrawal: 0,
  };

  statistics.forEach((statistic) => {
    total.TotalSportsBetBetsCount += statistic.TotalSportsBetBetsCount || 0;
    total.TotalSportsBetBonusBetsCount +=
      statistic.TotalSportsBetBonusBetsCount || 0;
    total.TotalSportsBetBonusGrossBalance +=
      statistic.TotalSportsBetBonusGrossBalance || 0;
    total.TotalSportsBetBonusTurnOver +=
      statistic.TotalSportsBetBonusTurnOver || 0;
    total.TotalSportsBetGrossBalance +=
      statistic.TotalSportsBetGrossBalance || 0;
    total.TotalSportsBetTurnOver += statistic.TotalSportsBetTurnOver || 0;
    total.TotalDeposit += statistic.TotalDeposit || 0;
    total.TotalWithdrawal += statistic.TotalWithdrawal || 0;
  });

  return total;
}

export function PlayerStatsCards({ playerStatsAggregation }) {
  const { org, region } = useContext(OrgRegionContext);

  const orgRegionStatistics = findStatisticsByOrgAndRegion(
    playerStatsAggregation,
    org?.ID,
    region?.ID
  );

  const totalStats = sumStats(playerStatsAggregation);

  return (
    <Row className="my-4">
      <Col className="mt-3" lg={4} md={6} sm={6}>
        <ReportCard
          bgColor="#531D9C"
          heading="Overall Activity"
          secHeading="Total Turnover"
          value={`${toCurrency(totalStats.TotalSportsBetTurnOver)}`}
          // growthRate={`${10}`}
          cardFirst="Bets"
          cardSec="Gross Balance"
          bets={toHumanNumber(totalStats.TotalSportsBetBetsCount)}
          grossWin={`${toCurrency(totalStats.TotalSportsBetGrossBalance)}`}
        />
      </Col>
      <Col className="mt-3" lg={4} md={6} sm={6}>
        <ReportCard
          bgColor="#5D5FEF"
          heading="Deposit / Withdrawal"
          secHeading="Balance"
          value={`${toCurrency(
            totalStats.TotalDeposit - totalStats.TotalWithdrawal
          )}`}
          // growthRate={`${20}`}
          cardFirst="Deposit"
          cardSec="Withdrawal"
          bets={`${toCurrency(totalStats.TotalDeposit)}`}
          grossWin={`${toCurrency(totalStats.TotalWithdrawal)}`}
        />
      </Col>
      <Col className="mt-3" lg={4} md={6} sm={6}>
        <PlayerRegionGraph playerStatsAggregation={playerStatsAggregation} />
      </Col>
    </Row>
  );
}
