import "./playerCasesTable.css";

import React from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

import { toHumanDate, truncateText } from "../../../_helpers";

function getColumns(open, playerID) {
  const allColumns = [
    {
      id: 1,
      name: "CREATED",
      selector: (row) => row.Created,
      sortable: true,
      reorder: true,
      minWidth: "1",
      cell: (row) => {
        return (
          <Link to={`/players/${playerID}/cases/${row.ID}`}>
            {toHumanDate(row.Created)}
          </Link>
        );
      },
    },
    {
      id: 2,
      name: "CLOSED",
      selector: (row) => toHumanDate(row.Closed),
      sortable: true,
      reorder: true,
      minWidth: "1",
    },
    {
      id: 3,
      name: "AUTHOR",
      selector: (row) => `${row.User.FirstName} ${row.User.LastName}`,
      sortable: true,
      reorder: true,
      minWidth: "1",
    },
    {
      id: 4,
      name: "CASE TYPE",
      selector: (row) => row.CaseType,
      sortable: true,
      reorder: true,
      minWidth: "1",
    },
    {
      id: 5,
      name: "TITLE",
      selector: (row) => truncateText(row.Title),
      sortable: true,
      reorder: true,
      minWidth: "1",
    },
  ];

  let columns;

  if (open) {
    columns = allColumns.filter((column) => {
      return column.id !== 2; // Filter out Closed column
    });
  } else {
    columns = allColumns;
  }

  return columns;
}

export function PlayerCasesTable({ cases, playerID, open }) {
  return (
    <div className="playerCasesTable">
      <DataTable
        columns={getColumns(open, playerID)}
        data={cases}
        // Styling
        fixedHeader
        fixedHeaderScrollHeight="80vh"
        highlightOnHover
        persistTableHead
      />
    </div>
  );
}
