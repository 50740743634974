import { alertConstant } from "../_constants";

const initialState = {
  message: "",
  type: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case alertConstant.ALERT:
      return {
        ...state,
        message: action.payload.message,
        type: action.payload.type,
      };
    case alertConstant.CLEAR:
      return initialState;
    default:
      return initialState;
  }
};
