import { Fetcher, Middleware } from "openapi-typescript-fetch";
import { CustomRequestInit } from "openapi-typescript-fetch/dist/cjs/types";

import { apiServiceUrl, getAccessToken } from "../_helpers";
import { paths } from "./apiservice";

const fetcher = Fetcher.for<paths>();
const auth: Middleware = async (url, init, next) => {
  const token = getAccessToken();
  if (!token) {
    return next(url, init);
  }
  const headers = new Headers(init.headers);
  headers.append("Authorization", `Bearer ${token}`);
  const newInit: CustomRequestInit = {
    ...init,
    headers,
  };
  return next(url, newInit);
};

fetcher.configure({
  baseUrl: apiServiceUrl,
  use: [auth],
});

export const getBetsOverview = fetcher
  .path("/bets/overview/{orgID}")
  .method("get")
  .create();

export const getPlayers = fetcher
  .path("/players/{orgID}")
  .method("get")
  .create();

export const getPlayer = fetcher
  .path("/organizations/{orgID}/regions/{regionID}/players/{orgPlayerID}")
  .method("get")
  .create();

export const getPlayerStatsAggregation = fetcher
  .path(
    "/organizations/{orgID}/regions/{regionID}/players/{orgPlayerID}/stats-aggregation"
  )
  .method("get")
  .create();

export const getPlayerStats = fetcher
  .path("/organizations/{orgID}/regions/{regionID}/players/{orgPlayerID}/stats")
  .method("get")
  .create();

export const getPlayerScores = fetcher
  .path(
    "/organizations/{orgID}/regions/{regionID}/players/{orgPlayerID}/scores"
  )
  .method("get")
  .create();

export const getPlayerActivity = fetcher
  .path(
    "/organizations/{orgID}/regions/{regionID}/players/{orgPlayerID}/activity"
  )
  .method("get")
  .create();

export const getPlayerCases = fetcher
  .path("/organizations/{orgID}/regions/{regionID}/players/{orgPlayerID}/cases")
  .method("get")
  .create();

export const getPlayerCase = fetcher
  .path(
    "/organizations/{orgID}/regions/{regionID}/players/{orgPlayerID}/cases/{orgPlayerCaseID}"
  )
  .method("get")
  .create();

export const postOrgPlayerCase = fetcher
  .path("/organizations/{orgID}/regions/{regionID}/players/{orgPlayerID}/cases")
  .method("post")
  .create();

export const getPlayerCaseNotes = fetcher
  .path(
    "/organizations/{orgID}/regions/{regionID}/players/{orgPlayerID}/cases/{orgPlayerCaseID}/notes"
  )
  .method("get")
  .create();

export const postOrgPlayerCaseNote = fetcher
  .path(
    "/organizations/{orgID}/regions/{regionID}/players/{orgPlayerID}/cases/{orgPlayerCaseID}/notes"
  )
  .method("post")
  .create();

export const closeOrgPlayerCase = fetcher
  .path(
    "/organizations/{orgID}/regions/{regionID}/players/{orgPlayerID}/cases/{orgPlayerCaseID}"
  )
  .method("put")
  .create({ closed: true });

export const getPlayerBets = fetcher
  .path("/bets/{orgID}/{orgPlayerID}")
  .method("get")
  .create();

export const getOrgUsers = fetcher.path("/users").method("get").create();

export const postUserSignup = fetcher
  .path("/users/signup")
  .method("post")
  .create();

export const postUserSignin = fetcher
  .path("/users/signin")
  .method("post")
  .create();

export const postForceChangePassword = fetcher
  .path("/users/force-change-password")
  .method("post")
  .create();

export const getOrgReports = fetcher
  .path("/admin/reports")
  .method("get")
  .create();
