import axios from "axios";
import { purgeStoredState } from "redux-persist";

import {
  alertMessage,
  forgetPasswordFailure,
  forgetPasswordRequest,
  forgetPasswordSuccess,
  userChangePasswordFailure,
  userChangePasswordRequest,
  userChangePasswordSuccess,
  userFailure,
  userForceChangePassword,
  userLogoutRequest,
  userRequest,
  userSignupFailure,
  userSignupRequest,
  userSignupSuccess,
  userSuccess,
} from "../_actions";
import { alertConstant, userConstant } from "../_constants";
import {
  apiServiceUrl,
  getHttpAlertMessage,
  removeCookie,
  removeLocalStorage,
  setCookie,
  setLocalStorage,
  setOperators,
  setOrg,
  setSelectedOperatorFromOperators,
  setSelectedOperatorRegionFromOperator,
  setUser,
  store,
  TOAST_ERROR,
  TOAST_SUCCESS,
} from "../_helpers";
import {
  postForceChangePassword,
  postUserSignin,
  postUserSignup,
} from "./fetcher.ts";

export const userRegistration = async (formData) => {
  const callApi = () => {
    return async (dispatch) => {
      try {
        dispatch(userSignupRequest());
        const { firstName, lastName, email, organization } = formData;
        const userData = { firstName, lastName, email, organization };
        await postUserSignup(userData);
        dispatch(userSignupSuccess(alertConstant.newUserDetailsSentMsg(email)));
      } catch (err) {
        dispatch(userSignupFailure(getHttpAlertMessage(err)));
      }
    };
  };

  await store.dispatch(callApi());
};

export const userLogin = async (formData) => {
  const callApi = () => {
    return async (dispatch) => {
      try {
        const { email, password } = formData;
        dispatch(userRequest({ email }));
        const res = await postUserSignin({ email, password });
        const {
          TokenInfo,
          Org: org,
          User: user,
          Operators: operators,
        } = res.data;
        setCookie("accessToken", TokenInfo.AccessToken);
        setCookie("refreshToken", TokenInfo.RefreshToken);
        setCookie("idToken", TokenInfo.IdToken);

        setLocalStorage("accessToken", TokenInfo.AccessToken);
        setOrg(org);
        setUser(user);
        setOperators(operators);

        const selectedOperator = setSelectedOperatorFromOperators(operators);
        setSelectedOperatorRegionFromOperator(selectedOperator);

        dispatch(
          userSuccess({
            org,
            user,
          })
        );
      } catch (err) {
        // Using `openapi-typescript-fetch` requires us to use `getActualType()` to get the actual error object.
        const error = err.getActualType();
        // TODO: very deeply nested at this point. Refactor.
        // The user's password was correct, but they need to change it.
        if (error.data.ChallengeName === userConstant.NEW_PASSWORD_REQUIRED) {
          const challenge = {
            challengeName: error.data.ChallengeName,
            session: error.data.Session,
          };
          dispatch(userForceChangePassword({ challenge }));
        } else {
          dispatch(userFailure(getHttpAlertMessage(error.status)));
        }
      }
    };
  };
  await store.dispatch(callApi());
};

export const forceChangePassword = async (formData) => {
  const callApi = () => {
    return async (dispatch) => {
      try {
        const { email, newPassword, challenge } = formData;
        dispatch(userChangePasswordRequest());
        await postForceChangePassword({
          challengeName: challenge.challengeName,
          session: challenge.session,
          challengeResponses: {
            newPassword,
            username: email,
          },
        });
        dispatch(userChangePasswordSuccess());
      } catch (err) {
        dispatch(userChangePasswordFailure(getHttpAlertMessage(err)));
      }
    };
  };
  await store.dispatch(callApi());
};

// TODO: delete this and associated functions
export const confirmRegistration = async (formData) => {
  const callApi = () => {
    return async (dispatch) => {
      let message;
      let type;

      try {
        const { code, email } = formData;
        dispatch(userRequest({ email }));
        await axios.post(`${apiServiceUrl}/users/verify`, {
          code,
          email,
        });
        message = alertConstant.REGISTRATION_CONFIRMED;
        type = TOAST_SUCCESS;
        dispatch(userSuccess(message)); // TODO: change to use object
        userLogin({
          email,
          password: "",
        });
      } catch (err) {
        message = getHttpAlertMessage(err);
        type = TOAST_ERROR;
        dispatch(userFailure(err.message));
      }
      dispatch(alertMessage({ message, type }));
    };
  };

  await store.dispatch(callApi());
};

// TODO: delete this and associated functions
export const resendVeriCode = async (formData) => {
  const callApi = () => {
    return async (dispatch) => {
      let message;
      let type;

      try {
        const { email } = formData;
        dispatch(userRequest({ email }));
        await axios.post(`${apiServiceUrl}/users/resend-code`, {
          email,
        });
        message = alertConstant.VERIFICATION_SENT;
        type = TOAST_SUCCESS;
        dispatch(userSuccess(message)); // TODO: change to use object
      } catch (err) {
        dispatch(userFailure(err.message));
        message = getHttpAlertMessage(err);
        type = TOAST_ERROR;
      }
      dispatch(alertMessage({ message, type }));
    };
  };

  await store.dispatch(callApi());
};

// TODO: refactor to above approach that consolidates dispatch calls is needed - this still does not work!
export const forgetPassword = async (formData) => {
  const callApi = () => {
    return async (dispatch) => {
      let message;
      let type;

      try {
        dispatch(forgetPasswordRequest());
        const { email } = formData;
        await axios.post(`${apiServiceUrl}/users/forgot-password`, {
          email,
        });
        message = alertConstant.VERIFICATION_SENT;
        dispatch(forgetPasswordSuccess(message));
        type = TOAST_SUCCESS;
      } catch (err) {
        dispatch(forgetPasswordFailure(err.message));
        message = getHttpAlertMessage(err);
        type = TOAST_ERROR;
      }
      dispatch(alertMessage({ message, type }));
    };
  };

  await store.dispatch(callApi());
};

// TODO: refactor if above approach that consolidates dispatch calls is needed - need to check if this works!
export const newPassword = async (formData) => {
  const callApi = () => {
    return async (dispatch) => {
      let message;
      let type;

      try {
        dispatch(forgetPasswordRequest());
        const { email, password, code } = formData;
        await axios.post(`${apiServiceUrl}/users/confirm-forgot-password`, {
          email,
          password,
          code,
        });
        message = alertConstant.PASSWORD_RESET_SUCCESS;
        type = TOAST_SUCCESS;
        dispatch(forgetPasswordSuccess(message));
      } catch (err) {
        message = getHttpAlertMessage(err);
        type = TOAST_ERROR;
        dispatch(forgetPasswordFailure(err.message));
      }
      dispatch(alertMessage({ message, type }));
    };
  };

  await store.dispatch(callApi());
};

export const userLogout = async () => {
  const callApi = () => {
    return async (dispatch) => {
      dispatch(userLogoutRequest());
      removeCookie("accessToken");
      removeCookie("refreshToken");
      removeCookie("idToken");
      removeLocalStorage("accessToken");
      removeLocalStorage("org");
      removeLocalStorage("user");
      removeLocalStorage("operators");
      await purgeStoredState({ storage: window.localStorage });
    };
  };

  await store.dispatch(callApi());
};
