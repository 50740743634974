import "./playersDashboardTable.css";

import React, { useContext, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

import { OrgRegionContext, TimePeriodContext } from "../../../_contexts";
import {
  fromTimeParam,
  toCurrency,
  toHumanID,
  toTimeParam,
} from "../../../_helpers";
import { getPlayers } from "../../../_services";
import { useFetchData } from "../../../_services/dataServices";

const columns = [
  {
    id: 1,
    name: "META ID",
    selector: (row) => toHumanID(row.MetaID),
    sortable: true,
    sortOn: "metaid",
    minWidth: "1",
    left: true,
    cell: (row) => {
      if (row.CaseOpen) {
        return (
          <div>
            <span style={{ display: "inline-block", width: "20px" }}> ⚠️</span>
            <Link
              to={`/player/${row.OrgPlayerID}`}
              style={{ color: "#8B0000" }}
            >
              {toHumanID(row.MetaID)}
            </Link>
          </div>
        );
      }

      return (
        <div>
          <Link
            to={`/player/${row.OrgPlayerID}`}
            style={{ paddingLeft: "20px" }}
          >
            {toHumanID(row.MetaID)}
          </Link>
        </div>
      );
    },
  },
  {
    id: 2,
    name: "TAG",
    selector: (row) => {
      if (row.RgScore > 0) {
        return "RG";
      }
      return "NORMAL";
    },
    minWidth: "1",
    left: true,
  },
  {
    id: 3,
    name: "TURNOVER",
    selector: (row) =>
      toCurrency(row.Statistics.TotalSportsBetTurnOver) || toCurrency(0),
    sortable: true,
    sortOn: "turnover",
    minWidth: "1",
    left: true,
  },
  {
    id: 4,
    name: "GROSS BALANCE",
    selector: (row) =>
      toCurrency(row.Statistics.TotalSportsBetGrossBalance) || toCurrency(0),
    sortable: true,
    sortOn: "grosswin",
    minWidth: "1",
    left: true,
  },
  {
    id: 5,
    name: "NBR BETS",
    selector: (row) => row.Statistics.TotalSportsBetBetsCount || 0,
    sortable: true,
    sortOn: "bets",
    minWidth: "1",
    left: true,
  },
  {
    id: 6,
    name: "BONUS TURNOVER",
    selector: (row) =>
      toCurrency(row.Statistics.TotalSportsBetBonusTurnOver) || toCurrency(0),
    sortable: true,
    sortOn: "bonusturnover",
    minWidth: "1",
    left: true,
  },
  {
    id: 7,
    name: "BONUS GW",
    selector: (row) =>
      toCurrency(row.Statistics.TotalSportsBetBonusGrossBalance) ||
      toCurrency(0),
    sortable: true,
    sortOn: "bonusgrosswin",
    minWidth: "1",
    left: true,
  },
  {
    id: 8,
    name: "NBR BONUS BETS",
    selector: (row) => row.Statistics.TotalSportsBetBonusBetsCount || 0,
    sortable: true,
    sortOn: "bonusbets",
    minWidth: "1",
    left: true,
  },
  // {
  //   id: 9,
  //   name: "NETWORK APPS",
  //   selector: (row) => row.OrgCount || 0,
  //   sortable: true,
  //   sortOn: "orgcount",
  //   minWidth: "1",
  //   left: true,
  // },
];

export function PlayersDashboardTable({ includeZero, showCaseOpen, from, to }) {
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("grosswin");
  const [sortDirection, setSortDirection] = useState("desc");

  const { org, region } = useContext(OrgRegionContext);
  const { timePeriod } = useContext(TimePeriodContext);

  const now = new Date();
  const fromTime = fromTimeParam(now, timePeriod);
  const toTime = toTimeParam(now);

  const handleSort = (column, sortDir) => {
    setSortField(column.sortOn);
    setSortDirection(sortDir);
  };

  const { data, loading } = useFetchData({
    fetchFunction: getPlayers,
    args: {
      orgID: org?.ID,
      regionID: region?.ID,
      statsFrom: fromTime,
      statsTo: toTime,
      order: sortField,
      orderDirection: sortDirection,
      page: currentPage,
      perPage,
      includeZeroBets: includeZero,
      showCaseOpenOnly: showCaseOpen,
    },
    dependencies: [
      org,
      region,
      from,
      to,
      currentPage,
      perPage,
      sortField,
      sortDirection,
      includeZero,
      showCaseOpen,
    ],
    required: [org, region, fromTime, toTime],
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  return (
    <div className="playersDashboardTable">
      <DataTable
        columns={columns}
        data={data.results}
        responsive
        // Pagination
        pagination
        paginationServer
        paginationTotalRows={data.total}
        paginationPerPage={perPage}
        paginationRowsPerPageOptions={[50, 100]}
        progressPending={loading}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        onSort={handleSort}
        sortServer
        fixedHeader
        fixedHeaderScrollHeight="80vh"
        persistTableHead
      />
    </div>
  );
}
