import { React, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import { OrgRegionContext } from "../../_contexts";
import {
  formatPeriod,
  toCurrency,
  toHumanDate,
  toHumanNumber,
} from "../../_helpers";
import { useFetchOnDateRangeChange } from "../../_services/dataServices";
import { getBetsOverview } from "../../_services/fetcher.ts";
import { CardItem, RiskCard } from "..";

export default function DashboardComponent() {
  const navigate = useNavigate();
  const location = useLocation();

  const { org, region } = useContext(OrgRegionContext);

  let selectedPeriod = new URLSearchParams(location.search).get("period");
  if (selectedPeriod === null) {
    selectedPeriod = "7_days"; // default
  }

  const { data, loading } = useFetchOnDateRangeChange({
    fetchFunction: getBetsOverview,
    args: {
      orgID: org?.ID,
      regionID: region?.ID,
      period: selectedPeriod,
    },
    dependencies: [org, region],
    required: [org, region],
  });

  const navigateTo = (path) => {
    return () => navigate(path);
  };

  return (
    <div className="title">
      <h2>Overview {formatPeriod(data.Period)}</h2>
      <h5>Data last updated: {toHumanDate(data.DataFromDate)}</h5>
      <br />
      <Container>
        <Row className="mb-2 justify-content-center">
          <Col lg={3} md={6} sm={6}>
            <CardItem
              title="Total Players"
              value={toHumanNumber(data.TotalPlayers)}
              bgColor="#5D0FC0"
              navigate={navigateTo("/players")}
              loading={loading}
            />
          </Col>
          <Col lg={3} md={6} sm={6}>
            <CardItem
              title="Active Players"
              value={toHumanNumber(data.ActivePlayers)}
              bgColor="#5D0FC0"
              navigate={navigateTo("/players")}
              loading={loading}
            />
          </Col>
          <Col lg={3} md={6} sm={6}>
            <CardItem
              title="Deposit"
              value={toCurrency(data.Deposit)}
              bgColor="#5D0FC0"
              navigate={navigateTo("/players")}
              loading={loading}
            />
          </Col>
          <Col lg={3} md={6} sm={6}>
            <CardItem
              title="Withdrawal"
              value={toCurrency(data.Withdrawal)}
              bgColor="#5D0FC0"
              navigate={navigateTo("/players")}
              loading={loading}
            />
          </Col>
        </Row>

        <Row className="my-3 d-flex justify-content-around">
          <Col
            className="mt-3 d-flex justify-content-center"
            lg={3}
            md={4}
            sm={5}
          >
            <RiskCard
              bgColor="#5D0FC0"
              header="New Players"
              subHeader="# in Period"
              value={data.NewPlayers || 0}
              isCurrency={false}
              percent={data.NewPlayersChange || 0}
              navigate={navigateTo("/registrations")}
              loading={loading}
            />
          </Col>
          <Col
            className="mt-3 d-flex justify-content-center"
            lg={3}
            md={4}
            sm={5}
          >
            <RiskCard
              bgColor="#5D0FC0"
              header="Turnover"
              subHeader="Amount USD"
              isCurrency
              value={data.Turnover}
              navigate={navigateTo("/players")}
              percent={data.TurnoverChange || 0}
              loading={loading}
            />
          </Col>
          <Col
            className="mt-3 d-flex justify-content-center"
            lg={3}
            md={4}
            sm={5}
          >
            <RiskCard
              bgColor="#5D0FC0"
              header="Bonus Turnover"
              subHeader="Amount USD"
              isCurrency
              value={data.BonusTurnover}
              navigate={navigateTo("/players")}
              percent={data.BonusTurnoverChange || 0}
              loading={loading}
            />
          </Col>
          <Col
            className="mt-3 d-flex justify-content-center"
            lg={3}
            md={4}
            sm={5}
          >
            <RiskCard
              bgColor="#5D0FC0"
              header="Bets"
              subHeader="# in Period"
              value={data.BetCount}
              navigate={navigateTo("/players")}
              percent={data.BetCountChange || 0}
              loading={loading}
            />
          </Col>
        </Row>

        <Row className="mb-2 justify-content-center">
          <Col lg={3} md={6} sm={6}>
            <CardItem
              title="Risk Players (RP)"
              value={toHumanNumber(data.RgTotalPlayers)}
              bgColor="#E43336"
              navigate={navigateTo("/risk-accounts")}
              loading={loading}
            />
          </Col>
          <Col lg={3} md={6} sm={6}>
            <CardItem
              title="RP Deposit"
              value={toCurrency(data.RgDeposit)}
              bgColor="#E43336"
              navigate={navigateTo("/players")}
              loading={loading}
            />
          </Col>
          <Col lg={3} md={6} sm={6}>
            <CardItem
              title="RP Withdrawal"
              value={toCurrency(data.RgWithdrawal)}
              bgColor="#E43336"
              navigate={navigateTo("/players")}
              loading={loading}
            />
          </Col>
        </Row>

        <Row className="my-3 d-flex justify-content-around">
          <Col
            className="mt-3 d-flex justify-content-center"
            lg={3}
            md={4}
            sm={5}
          >
            <RiskCard
              bgColor="#E43336"
              header="New RP"
              subHeader="# in Period"
              value={data.RgNewPlayers || 0}
              navigate={navigateTo("/risk-accounts")}
              isCurrency={false}
              percent={data.RgNewPlayersChange || 0}
              loading={loading}
            />
          </Col>
          <Col
            className="mt-3 d-flex justify-content-center"
            lg={3}
            md={4}
            sm={5}
          >
            <RiskCard
              bgColor="#E43336"
              header="RP Turnover"
              subHeader="Amount USD"
              isCurrency
              value={data.RgTurnover}
              navigate={navigateTo("/risk-accounts")}
              percent={data.RgTurnoverChange || 0}
              loading={loading}
            />
          </Col>
          <Col
            className="mt-3 d-flex justify-content-center"
            lg={3}
            md={4}
            sm={5}
          >
            <RiskCard
              bgColor="#E43336"
              header="RP Bonus Turnover"
              subHeader="Amount USD"
              isCurrency
              value={data.RgBonusTurnover}
              navigate={navigateTo("/risk-accounts")}
              percent={data.RgBonusTurnoverChange || 0}
              loading={loading}
            />
          </Col>
          <Col
            className="mt-3 d-flex justify-content-center"
            lg={3}
            md={4}
            sm={5}
          >
            <RiskCard
              bgColor="#E43336"
              header="RP Bets"
              subHeader="# in Period"
              value={data.RgBetCount}
              navigate={navigateTo("/risk-accounts")}
              percent={data.RgBetCountChange || 0}
              loading={loading}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
