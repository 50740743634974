import { format, parseISO } from "date-fns";

export const formatPeriod = (period) => {
  if (!period) {
    return "";
  }
  if (period === "7_days") {
    return "7 Days";
  }

  if (period === "30_days") {
    return "30 Days";
  }

  if (period === "90_days") {
    return "90 Days";
  }

  return "Unknown Period";
};

export const toHumanNumber = (num, minDigits = 0) => {
  return num
    ? num.toLocaleString(undefined, {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: 2,
      })
    : 0;
};

let idMatches = new Map();

let currentRegion = "";

export const setCurrentRegion = (region) => {
  currentRegion = region.toString().toUpperCase();
};

export const idMap = (map) => {
  idMatches = new Map(map);
};

export const toHumanID = (id) => {
  if (id === undefined || id === null) {
    return "";
  }

  if (id.length < 6) {
    return id;
  }

  // The key in the meta id map is using the region + meta id to prevent clashes
  const idKey = `${currentRegion}_${id}`;
  let suffix = idMatches.get(idKey) || "";
  suffix = suffix ? ` [${suffix}]` : "";
  return `${id.slice(0, 6)}${suffix}`;
};

export const toHumanDate = (date, dateFormat = "MM/dd/yyyy HH:mm") => {
  if (!date) {
    return "";
  }
  const parsedDate = parseISO(date);
  return format(parsedDate, dateFormat);
};

// This is a hack to ensure that we don't send "%2F" to the API Gateway which will interpret that as a path separator and therefore not find the associated endpoint
export const encode2F = (str) => {
  // replace any occurrences of "%2F" in the string with "--2F--" and return the new string
  return str.replace(/%2F/g, "--2F--");
};

const usCurrencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const toCurrency = (num) => {
  return usCurrencyFormatter.format(num || 0);
};

export const truncateText = (text, maxLength = 30, suffix = "...") => {
  return text.length > maxLength ? text.slice(0, maxLength) + suffix : text;
};

export const toAge = (birthYear) => {
  const now = new Date();
  const age = now.getFullYear() - birthYear;
  return age;
};

export const toGender = (gender) => {
  if (gender === "M") {
    return "Male";
  }
  if (gender === "F") {
    return "Female";
  }
  return undefined;
};

export const toDateOnly = (dateTimeString) => {
  const date = new Date(dateTimeString);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 to month as it's zero-based
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const decimalToPercentage = (decimal) => {
  return `${(decimal * 100).toFixed(2)}%`;
};

export const toStateFullName = (code) => {
  const stateCodeMap = {
    AL: "Alabama",
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
  };

  return stateCodeMap[code] || "Unknown";
};

export const fromTimeParam = (now, timePeriod) => {
  const fromDate = new Date(now);
  fromDate.setUTCHours(0);
  fromDate.setUTCMinutes(0);
  fromDate.setUTCSeconds(0);
  fromDate.setUTCMilliseconds(0);

  fromDate.setDate(fromDate.getDate() - timePeriod.days);
  const fromParam = fromDate.toISOString();
  return fromParam;
};

export const toTimeParam = (now) => {
  const toDate = new Date(now);
  toDate.setUTCHours(0);
  toDate.setUTCMinutes(0);
  toDate.setUTCSeconds(0);
  toDate.setUTCMilliseconds(0);

  const toParam = toDate.toISOString();
  return toParam;
};
