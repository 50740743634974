import { useCallback, useEffect, useRef, useState } from "react";

import { alertFetchFailed } from "../_actions";
import { useSharedDateRange } from "../_components/header/Date";
import { logger, store } from "../_helpers";

export const fetchData = async ({
  fetchFunction,
  setLoading,
  setData,
  args = {},
}) => {
  setLoading(true);
  try {
    const { data } = await fetchFunction(args);
    setData(data);
  } catch (error) {
    logger.error("Error fetching data: ", error);
    store.dispatch(alertFetchFailed());
  } finally {
    setLoading(false);
  }
};

export function useFetchData({
  fetchFunction,
  args = {},
  dependencies = [],
  required = [],
}) {
  // Check if any item in the required array is null
  const shouldFetch = required.every(
    (item) => item !== null && item !== undefined
  );

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const fetch = () => {
    if (shouldFetch) {
      fetchData({
        fetchFunction,
        setLoading,
        setData,
        args,
      });
    }
  };

  useEffect(() => {
    fetch();
  }, dependencies);

  const refetch = useCallback(() => {
    fetch();
  }, dependencies);

  return { data, loading, refetch };
}

export function useFetchOnDateRangeChange({
  fetchFunction,
  args = {},
  dependencies = [],
  required = [],
}) {
  const { startDate, endDate } = useSharedDateRange();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const dateRef = useRef({});
  const dependencyRef = useRef({});

  const updateDependencyRef = () => {
    dependencyRef.current = dependencies.reduce((acc, dep) => {
      acc[dep] = dep;
      return acc;
    }, {});
  };

  useEffect(() => {
    if (!endDate) return;

    const shouldFetchData =
      dateRef.current.startDate !== startDate ||
      dateRef.current.endDate !== endDate ||
      !areArraysEqual(Object.values(dependencyRef.current), dependencies);

    const allRequired = required.every((item) => item !== null);

    if (shouldFetchData && allRequired) {
      dateRef.current = { startDate, endDate };
      updateDependencyRef();
      fetchData({
        fetchFunction,
        setLoading,
        setData,
        args: {
          ...args,
          from: startDate.toISOString(),
          to: endDate.toISOString(),
        },
      });
    }
  }, [startDate, endDate, ...dependencies]);

  return { data, loading };
}

function areArraysEqual(arr1, arr2) {
  return (
    arr1.length === arr2.length &&
    arr1.every((value, index) => Object.is(value, arr2[index]))
  );
}
