import { userConstant } from "../_constants";

const initialState = {
  err: "",
  loading: false,
  user: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case userConstant.LOGIN_REQUEST:
      return {
        ...state,
        err: "",
        loading: true,
        user: action.user,
      };
    case userConstant.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.user,
        err: userConstant.LOGIN_SUCCESS,
      };
    case userConstant.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        err: action.err,
      };
    case userConstant.NEW_PASSWORD_REQUIRED:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          challenge: { ...action.challenge, pending: true },
        },
        err: action.err,
      };
    case userConstant.NEW_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstant.NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        err: "",
        user: { ...state.user, challenge: { pending: false } },
      };
    case userConstant.NEW_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        err: action.err,
        user: { ...state.user, challenge: { pending: false } },
      };
    case userConstant.LOGOUT:
      return initialState;
    case userConstant.SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstant.SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        err: "",
      };
    case userConstant.SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        err: action.err,
      };
    default:
      return state;
  }
};
