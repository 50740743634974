export const userConstant = {
  LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USER_LOGIN_FAILURE",
  LOGOUT: "USER_LOGOUT",
  NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
  NEW_PASSWORD_REQUEST: "NEW_PASSWORD_REQUEST",
  NEW_PASSWORD_SUCCESS: "NEW_PASSWORD_SUCCESS",
  NEW_PASSWORD_FAILURE: "NEW_PASSWORD_FAILURE",
  SIGNUP_REQUEST: "USER_SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "USER_SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "USER_SIGNUP_FAILURE",
};
