import { React, useContext } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { OrgRegionContext } from "../../_contexts";
import { orgPlayerCaseNoteCreation } from "../../_services";

export default function NewCaseNoteModal({
  onHide,
  orgPlayerID,
  orgPlayerCaseID,
  ...props
}) {
  const { register, handleSubmit, reset } = useForm();

  const { org, region } = useContext(OrgRegionContext);

  const closeModal = () => {
    onHide();
  };

  // credential and validations work
  const onSubmit = async (formData) => {
    await orgPlayerCaseNoteCreation({
      ...formData,
      orgID: org?.ID,
      regionID: region?.ID,
      orgPlayerID,
      orgPlayerCaseID,
    });
    reset();
    closeModal();
  };

  return (
    <Modal {...props}>
      <Modal.Header>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <Modal.Title>New Note</Modal.Title>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Add new note information:</Form.Label>
            <Form.Control
              as="textarea"
              style={{
                height: "200px",
                width: "100%",
                overflow: "auto",
              }}
              placeholder="Content"
              {...register("content", {
                required: "Content is required",
              })}
            />
          </Form.Group>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Button className="signup" type="submit">
              Create
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
