import { alertConstant, userConstant } from "../_constants";
import { TOAST_ERROR, TOAST_SUCCESS, TOAST_WARNING } from "../_helpers";

export const userRequest = (user) => {
  return {
    type: userConstant.LOGIN_REQUEST,
    user,
  };
};

export const userSuccess = (user) => {
  return (dispatch) => {
    dispatch({
      type: userConstant.LOGIN_SUCCESS,
      user,
    });
  };
};

export const userFailure = (err) => {
  return (dispatch) => {
    dispatch({
      type: userConstant.LOGIN_FAILURE,
      err,
    });
    dispatch({
      type: alertConstant.ALERT,
      payload: {
        message: err,
        type: TOAST_ERROR,
      },
    });
  };
};

export const userLogoutRequest = () => {
  return {
    type: userConstant.LOGOUT,
  };
};

export const userForceChangePassword = ({ challenge }) => {
  return (dispatch) => {
    dispatch({
      type: userConstant.NEW_PASSWORD_REQUIRED,
      challenge,
      err: alertConstant.NEW_PASSWORD_REQUIRED,
    });
    dispatch({
      type: alertConstant.ALERT,
      payload: {
        message: alertConstant.NEW_PASSWORD_REQUIRED,
        type: TOAST_WARNING,
      },
    });
  };
};

export const userChangePasswordRequest = () => {
  return {
    type: userConstant.NEW_PASSWORD_REQUEST,
  };
};

export const userChangePasswordSuccess = () => {
  return (dispatch) => {
    dispatch({
      type: userConstant.NEW_PASSWORD_SUCCESS,
    });
    dispatch({
      type: alertConstant.ALERT,
      payload: {
        message: alertConstant.PASSWORD_RESET_SUCCESS,
        type: TOAST_SUCCESS,
      },
    });
  };
};

export const userChangePasswordFailure = (err) => {
  return (dispatch) => {
    dispatch({
      type: userConstant.NEW_PASSWORD_FAILURE,
      err,
    });
    dispatch({
      type: alertConstant.ALERT,
      payload: {
        message: err,
        type: TOAST_ERROR,
      },
    });
  };
};

export const userSignupRequest = () => {
  return {
    type: userConstant.SIGNUP_REQUEST,
  };
};

export const userSignupSuccess = (message) => {
  return (dispatch) => {
    dispatch({
      type: userConstant.SIGNUP_SUCCESS,
    });
    dispatch({
      type: alertConstant.ALERT,
      payload: {
        message,
        type: TOAST_SUCCESS,
      },
    });
  };
};

export const userSignupFailure = (err) => {
  return (dispatch) => {
    dispatch({
      type: userConstant.SIGNUP_FAILURE,
      err,
    });
    dispatch({
      type: alertConstant.ALERT,
      payload: {
        message: err,
        type: TOAST_ERROR,
      },
    });
  };
};
