import React, { useState } from "react";

import { AsideLeft } from "../../_components";
import { AsideRight } from "./asideRight";

export function DataSearch() {
  const [isActive, setActive] = useState("false");
  const handleIsActive = (bool) => {
    setActive(bool);
  };
  return (
    <div className={`wrapper ${isActive ? "" : "open"}`}>
      <AsideLeft />
      <AsideRight handleIsActive={handleIsActive} />
    </div>
  );
}
