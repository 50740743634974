import * as Plot from "@observablehq/plot";

import { toDateOnly } from "../../../_helpers";

function plotCustomPointer(data, { x, y, yLabel, color }) {
  return Plot.marks(
    Plot.ruleX(
      data,
      Plot.pointerX({
        x,
        py: y,
        stroke: color,
        strokeDasharray: "3,4",
        opacity: 0.75,
      })
    ),
    Plot.tip(
      data,
      Plot.pointerX({
        title: (s) =>
          [toDateOnly(s.StatisticDate), `${yLabel}: ${s[y]}`].join("\n"),
        x,
        y,
      })
    )
  );
}

export const Graph = (data, { x, y, width, color, yLabel, yInterval }) =>
  Plot.plot({
    style: { backgroundColor: "white" },
    width,
    height: 450,
    marginTop: 40,
    marginLeft: 80,
    x: {
      label: null,
      interval: "day",
    },
    y: {
      label: yLabel,
      interval: yInterval,
      nice: 5,
    },
    marks: [
      // Line Chart and horizontal grid
      Plot.ruleY([0], { stroke: "lightgray" }), // draw an horizontal line at 0
      Plot.gridY({ stroke: "#E8EAED", strokeWidth: 1, strokeOpacity: 0.5 }),
      Plot.rectY(data, {
        x,
        y,
        stroke: color,
        fill: "#445e7ad9",
        strokeWidth: 2,
      }),
      // Linear Regression
      Plot.linearRegressionY(data, {
        x,
        y,
        stroke: "#363740",
        strokeWidth: 1.5,
        strokeDasharray: "3,4",
        opacity: 0.75,
      }),
      // Tip
      plotCustomPointer(data, { x, y, yLabel, color }),
    ],
  });
