import "./asideRight.css";

import React from "react";

import DashboardComponent from "../../_components/dashboard/dashboard";

export default function AsideRight() {
  return (
    <div className="asideRight">
      <DashboardComponent />
    </div>
  );
}
