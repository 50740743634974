import "./playerStatsGraph.css";

import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";

import { fillEmptyValues } from "../statsUtils.js";
import { Graph } from "./graph.js";

const statsDropdown = [
  {
    id: "SportsBetTurnOver",
    label: "Turnover",
  },
  {
    id: "SportsBetBetsCount",
    label: "Bets Count",
    interval: 1,
  },
  {
    id: "SportsBetGrossBalance",
    label: "Gross Balance",
  },
  {
    id: "SportsBetBonusTurnOver",
    label: "Bonus Turnover",
  },
  {
    id: "SportsBetBonusBetsCount",
    label: "Bonus Bets Count",
    interval: 1,
  },
  {
    id: "SportsBetBonusGrossBalance",
    label: "Bonus Gross Balance",
  },
];

function transformStas(stats) {
  return stats.map((stat) => {
    return {
      ...stat,
      StatisticDate: new Date(stat.StatisticDate),
    };
  });
}

export function PlayerStatsGraph({ from, to, stats }) {
  const [selectedStat, setSelectedStat] = useState(statsDropdown[0]);

  const allStats = fillEmptyValues(from, to, stats);
  const transformedStats = transformStas(allStats);

  const graphDivRef = useRef();

  useEffect(() => {
    if (allStats === undefined) return;

    const plot = Graph(transformedStats, {
      x: "StatisticDate",
      y: selectedStat.id,
      width: graphDivRef.current.offsetWidth,
      color: "#445e7ad9",
      yLabel: selectedStat.label,
      yInterval: selectedStat.interval,
    });

    graphDivRef.current.append(plot);

    // eslint-disable-next-line consistent-return
    return () => plot.remove();
  }, [allStats, selectedStat]);

  function handleItemClick(stat) {
    setSelectedStat(stat);
    document.activeElement.blur();
  }

  return (
    <div
      style={{
        marginTop: "30px",
      }}
    >
      <h4>Activity</h4>
      <div
        className="d-flex align-items-end justify-content-end"
        style={{
          paddingTop: "20px",
          paddingRight: "20px",
          backgroundColor: "white",
        }}
      >
        <Dropdown align="end">
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            className="statsGraphDropdownToggle text-right"
          >
            <span>{selectedStat.label}</span>
          </Dropdown.Toggle>

          <Dropdown.Menu className="statsGraphDropdownMenu">
            {statsDropdown.map((stat) => (
              <React.Fragment key={stat.id}>
                <Dropdown.Item
                  onClick={() => handleItemClick(stat)}
                  className="statsGraphDropdownItem"
                >
                  {stat.label}
                </Dropdown.Item>
              </React.Fragment>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div style={{ marginTop: "0px" }} ref={graphDivRef} />
    </div>
  );
}
