import { forgetPassword } from "../_constants";

export const forgetPasswordRequest = () => {
  return {
    type: forgetPassword.FORGET_PASSWORD_REQUEST,
  };
};

export const forgetPasswordSuccess = (data) => {
  return {
    type: forgetPassword.FORGET_PASSWORD_SUCCESS,
    user: data,
  };
};

export const forgetPasswordFailure = (err) => {
  return {
    type: forgetPassword.FORGET_PASSWORD_FAILURE,
    err,
  };
};
