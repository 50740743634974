import "./subscriptionReportsTable.css";

import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import { logger } from "../../../_helpers";
import { getOrgReports } from "../../../_services";

const columns = [
  {
    id: 1,
    name: "Report",
    selector: (row) => row.Name,
    minWidth: "1",
    left: true,
  },
  {
    id: 2,
    name: "Description",
    selector: (row) => row.Description,
    minWidth: "1",
    left: true,
  },
  {
    id: 3,
    name: "Active",
    selector: (row) => (row.Enabled ? "Yes" : "No"),
    minWidth: "1",
    left: true,
  },
];

export function SubscriptionReportsTable() {
  const [reports, setUsers] = useState([]);

  useEffect(async () => {
    const abortController = new AbortController();
    const { signal } = abortController;

    try {
      const result = await getOrgReports({ signal });
      setUsers(result.data);
    } catch (error) {
      if (error.name !== "AbortError") {
        logger.error("Error fetching users: ", error);
      }
    }
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <div className="subscriptionReportsTable">
      <DataTable
        columns={columns}
        data={reports}
        defaultSortFieldId={1}
        responsive
        // Header
        fixedHeader
        fixedHeaderScrollHeight="80vh"
        persistTableHead
      />
    </div>
  );
}
