import "./playerCaseNotesTable.css";

import React from "react";
import DataTable from "react-data-table-component";

import { toHumanDate } from "../../../_helpers";

const columns = [
  {
    id: 1,
    name: "CREATED",
    selector: (row) => toHumanDate(row.Created),
    sortable: true,
    reorder: true,
    width: "200px",
  },
  {
    id: 3,
    name: "AUTHOR",
    selector: (row) => `${row.User.FirstName} ${row.User.LastName}`,
    sortable: true,
    reorder: true,
    width: "200px",
  },
  {
    id: 5,
    name: "CONTENT",
    selector: (row) => row.Content,
    sortable: true,
    reorder: true,
    minWidth: "500px",
    wrap: true, // Make sure the content wraps
    cell: (row) => {
      return <div style={{ whiteSpace: "normal" }}>{row.Content}</div>;
      // Apply a style directly to the cell content to ensure text wrapping.
    },
  },
];

export function PlayerCaseNotesTable({ notes }) {
  return (
    <div className="playerCaseNotesTable">
      <DataTable
        columns={columns}
        data={notes}
        // Styling
        fixedHeader
        fixedHeaderScrollHeight="80vh"
        highlightOnHover
        persistTableHead
      />
    </div>
  );
}
