import "./playerDetails.css";

import React, { useContext, useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { OrgRegionContext } from "../../_contexts";
import { toHumanID } from "../../_helpers";
import { getPlayer } from "../../_services";
import { useFetchData } from "../../_services/dataServices";
import { PlayerActivity } from "./playerActivity";
import { PlayerCases } from "./playerCases";
import { PlayerDemographicInfo } from "./playerDemographicInfo";

export function AsideRight() {
  const navigate = useNavigate();
  const location = useLocation();

  const { org, region } = useContext(OrgRegionContext);
  const { playerId } = useParams();

  const { data: playerResponse } = useFetchData({
    fetchFunction: getPlayer,
    args: {
      orgID: org?.ID,
      regionID: region?.ID,
      orgPlayerID: playerId,
    },
    dependencies: [org, region, playerId],
    required: [org, region],
  });

  const { player } = playerResponse || {};
  const [activeTab, setActiveTab] = useState("activity");

  const handleTabChange = (selectedTab) => {
    setActiveTab(selectedTab);
    const searchParams = new URLSearchParams(location.search);
    navigate(`${location.pathname}?${searchParams.toString()}#${selectedTab}`);
  };

  useEffect(() => {
    // Check if the URL contains a hash and set the active tab accordingly
    const hash = location.hash.replace("#", "");
    if (hash && hash !== activeTab) {
      setActiveTab(hash);
    }
  }, [location.hash, activeTab]);

  return (
    <div className="asideRight">
      <div className="title">
        <h2>{`Player: ${toHumanID(player?.MetaID)}`}</h2>
      </div>

      <PlayerDemographicInfo />

      <Tabs activeKey={activeTab} onSelect={handleTabChange} className="mb-3">
        <Tab eventKey="activity" title="Activity">
          <PlayerActivity player={player} />
        </Tab>
        <Tab eventKey="cases" title="Cases">
          <PlayerCases player={player} />
        </Tab>
      </Tabs>
    </div>
  );
}
