import "./Signup.css";

import React, { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { userRegistration } from "../../_services";

export function SignUpAsideRight({ handleActive }) {
  // credential and validations work
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // submit loading work
  const [loading, setLoading] = useState(false);
  const submitLoading = useSelector((state) => state.user.loading);

  useEffect(() => {
    if (submitLoading === true) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [submitLoading]);

  const organization = useSelector((state) => state.user.user.organization);

  // credential and validations work
  const onSubmit = async (formData) => {
    await userRegistration({ ...formData, organization });
    reset();
  };

  return (
    <div className="asideRight">
      <div className="title">
        <h2>Register a new user</h2>
      </div>

      <div className="signup-wrapper">
        <div className="signup-container">
          <div className="signup-right">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <h3 className="title text-center">New user</h3>
              <Form.Group className="mb-3 mt-5">
                <h6>First Name</h6>
                <Form.Control
                  type="text"
                  placeholder="e.g. 'John'"
                  name="name"
                  {...register("firstName", {
                    required: "First name is required",
                  })}
                />
                <p>
                  <span style={{ color: "rgb(206, 81, 81)" }}>
                    {errors.name?.message}
                  </span>
                </p>
              </Form.Group>

              <Form.Group className="mb-">
                <h6>Last Name</h6>
                <Form.Control
                  type="text"
                  placeholder="e.g. 'Doe'"
                  name="name"
                  {...register("lastName", {
                    required: "Last name is required",
                  })}
                />
                <p>
                  <span style={{ color: "rgb(206, 81, 81)" }}>
                    {errors.name?.message}
                  </span>
                </p>
              </Form.Group>

              <Form.Group className="mb-3">
                <h6>Email</h6>
                <Form.Control
                  type="email"
                  placeholder="e.g. 'john.doe@gmail.com'"
                  name="email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please enter a valid email address",
                    },
                  })}
                />
                <p style={{ color: "rgb(206, 81, 81)" }}>
                  {errors.email?.message}
                </p>
              </Form.Group>

              <Form.Group
                className="mb-3 mt-4 d-flex align-items-center"
                controlId="formBasicCheckbox"
              >
                <Button
                  className="regBtn"
                  variant="primary w-100"
                  type="submit"
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" variant="light" />
                  ) : (
                    "Register new user"
                  )}
                </Button>
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
