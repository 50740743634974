import "./playersDashboard.css";

import { React, useEffect, useState } from "react";
import { Card, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import { PeriodDropdown, PlayersDashboardTable } from "../../_components";

export function AsideRight() {
  const location = useLocation();
  const navigate = useNavigate();

  const [includeZeroActivity, setIncludeZeroActivity] = useState(false);
  const [showCaseOpenOnly, setShowCaseOpenOnly] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const includeZeroParam = params.get("includeZero") === "true";
    const showCaseOpenParam = params.get("showCaseOpen") === "true";
    // Set values
    setIncludeZeroActivity(includeZeroParam);
    setShowCaseOpenOnly(showCaseOpenParam);

    // Update URL params
    params.set("includeZero", includeZeroParam);
    params.set("showCaseOpen", showCaseOpenParam);
    navigate(`?${params.toString()}`);
  }, [window.location.search]);

  const handleIncludeZeroActivityToggleChange = (event) => {
    const includeZero = event.target.checked;

    setIncludeZeroActivity(includeZero);

    const params = new URLSearchParams(location.search);
    params.set("includeZero", includeZero);
    navigate(`?${params.toString()}`);
  };

  const handleShowCaseOpenOnlyToggleChange = (event) => {
    const showCaseOpen = event.target.checked;

    setShowCaseOpenOnly(showCaseOpen);

    const params = new URLSearchParams(location.search);
    params.set("showCaseOpen", showCaseOpen);
    navigate(`?${params.toString()}`);
  };

  return (
    <div className="asideRight">
      <div className="title">
        <h2>Players</h2>
      </div>
      <Row>
        <Form.Group className="d-flex align-items-left">
          <Form.Label>Include zero activity players&nbsp;&nbsp;</Form.Label>
          <Form.Check
            type="switch"
            id="custom-switch"
            label=""
            checked={includeZeroActivity}
            onChange={handleIncludeZeroActivityToggleChange}
          />
          <Form.Label>&nbsp;&nbsp;Show case open only&nbsp;&nbsp;</Form.Label>
          <Form.Check
            type="switch"
            id="custom-switch"
            label=""
            checked={showCaseOpenOnly}
            onChange={handleShowCaseOpenOnlyToggleChange}
          />
        </Form.Group>
      </Row>
      <Row className="d-flex row">
        <Card className="bigCard">
          <div className="col md-2" />
          <div className="col col-md-6" />

          <div className="mt-2 mb-3">
            <Card className="mb-3">
              <PlayersDashboardTable
                includeZero={includeZeroActivity}
                showCaseOpen={showCaseOpenOnly}
              />
            </Card>
          </div>
        </Card>
      </Row>
    </div>
  );
}
