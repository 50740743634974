import "./card.css";

import React from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import Icon from "react-icons-kit";
import { caretDown, caretUp } from "react-icons-kit/fa";

import { toCurrency } from "../../_helpers";

export function CardItem({
  title,
  value,
  bgColor,
  navigate,
  disabled,
  loading,
}) {
  return (
    <Button
      className="itemCard"
      style={{ backgroundColor: bgColor }}
      onClick={navigate}
      disabled={disabled}
    >
      <div className="info">
        <h5 className="title mb-4" style={{ color: "white" }}>
          {title}
        </h5>
        {loading ? (
          <Spinner animation="border" variant="secondary" />
        ) : (
          <h1 className="title mb-0" style={{ color: "white" }}>
            {value}
          </h1>
        )}
      </div>
    </Button>
  );
}

export function CardItem2({
  title,
  desc1,
  value1,
  desc2,
  value2,
  bgColor,
  disabled,
}) {
  return (
    <Button
      className="itemCard"
      style={{ backgroundColor: bgColor }}
      disabled={disabled}
    >
      <div className="info">
        <h4 className="title mb-4">{title}</h4>
        <h6 className="title mb-0">
          {desc1} : <span style={{ fontSize: "1.8rem" }}>{value1}</span>
        </h6>
        <h6 className="title mb-0">
          {desc2} : <span style={{ fontSize: "1.8rem" }}>{value2}</span>
        </h6>
      </div>
    </Button>
  );
}

export function ReportCard(props) {
  const {
    bgColor,
    heading,
    secHeading,
    value,
    // growthRate,
    cardFirst,
    cardSec,
    bets,
    grossWin,
  } = props;
  return (
    <div className="reportCard" style={{ backgroundColor: bgColor }}>
      <div className="heading d-flex align-items-center justify-content-center">
        <p>{heading}</p>
      </div>
      <Row className="secHeading d-flex align-items-center justify-content-center">
        {secHeading}
      </Row>
      <Row>
        <span className="amount d-flex align-items-center justify-content-center">
          {value}
        </span>
        <span>
          {/* {growthRate >= 0 ? (
              <span className="growthPosRate">
                <Icon size={40} icon={caretUp} />
                {growthRate}%
              </span>
            ) : (
              <span className="growthNegRate">
                <Icon size={40} icon={caretDown} />
                {growthRate}%
              </span>
            )} */}
        </span>
      </Row>
      <Row className="betsDiv d-flex">
        <Col>
          <p className="betText text-justify">{cardFirst}</p>
          <h6 className="betValue text-justify">{bets}</h6>
        </Col>
        <Col>
          <p className="betText">{cardSec}</p>
          <h6 className="betValue">{grossWin}</h6>
        </Col>
      </Row>
    </div>
  );
}

export function RiskCard(props) {
  const {
    bgColor,
    header,
    subHeader,
    value,
    isCurrency,
    percent,
    description,
    loading,
    navigate,
  } = props;
  const amount = isCurrency ? toCurrency(value) : value;

  return (
    <Button
      className="riskCard"
      style={{ backgroundColor: bgColor }}
      onClick={navigate}
    >
      <div style={{ backgroundColor: bgColor }}>
        <div className="heading">
          <p>{header}</p>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" variant="secondary" />
          </div>
        ) : (
          <div className="secHeading">
            <p>{subHeader}</p>
            <span>
              <span className="amount">{amount}</span>
              <span>
                {percent >= 0 ? (
                  <span className="growthPosRate">
                    <Icon size={40} icon={caretUp} />
                    {percent}%
                  </span>
                ) : (
                  <span className="growthNegRate">
                    <Icon size={40} icon={caretDown} />
                    {percent}%
                  </span>
                )}
              </span>
            </span>
            <p className="desc">{description}</p>
          </div>
        )}
      </div>
    </Button>
  );
}
