import "./settings.css";

import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { RegisteredUsersTable } from "../../_components/table/registeredUsersTable/registeredUsersTable";
import { SubscriptionReportsTable } from "../../_components/table/subscriptionReportsTable/subscriptionReportsTable";
import LocalTranslationFileModal from "./LocalTranslationFileModal";

export function AsideRight(props) {
  const { handleIsActive } = props;
  const handleActive = (bool) => {
    handleIsActive(bool);
  };

  const reloadWithModal = () => {
    window.location.href = "/admin?showModal=true";
  };

  const deleteTranslationFile = () => {
    localStorage.removeItem("ltf");
    window.location.href = "/admin";
  };

  const isPresent = localStorage.getItem("ltf") !== null;

  let showModal = "false";
  const urlParams = new URLSearchParams(window.location.search);
  showModal = urlParams.get("showModal");

  if (showModal === "true") {
    return (
      <div className="asideRight">
        <LocalTranslationFileModal
          className="modalMain"
          backdrop="static"
          show={showModal}
        />
      </div>
    );
  }

  return (
    <div className="asideRight">
      <div className="title">
        <h2>Settings</h2>
      </div>
      <div>
        <em>
          Contact idPair for any changes to the settings here below. Send an
          email to <a href="mailto:admin@idpair.com">admin@idpair.com</a> and
          explain your request.
        </em>
      </div>
      <div className="header">Subscription Reports</div>
      <div className="d-flex align-items-center justify-content-center">
        <SubscriptionReportsTable />
      </div>
      <div className="header">Registered Users</div>
      <div className="d-flex align-items-center justify-content-center">
        <RegisteredUsersTable />
      </div>
      <div className="topmargin d-flex align-items-centermt-5 justify-content-left">
        <Link className="link" to="/sign-up">
          <Button className="signup" variant="info">
            Register New User
          </Button>
        </Link>
      </div>
      <div className="header">Local Translation File</div>
      <div className="d-flex align-items-center justify-content-left">
        {isPresent ? "ACTIVE" : "NOT AVAILABLE"}
      </div>
      <div className="topmargin d-flex align-items-centermt-5 justify-content-left">
        <Button className="signup" variant="info" onClick={reloadWithModal}>
          Upload Translation File
        </Button>
        &nbsp;
        <Button
          className="signup"
          variant="info"
          onClick={deleteTranslationFile}
        >
          Delete Translation File
        </Button>
      </div>
    </div>
  );
}
