import "./ForgetPassword.css";

import React, { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { alertConstant } from "../../_constants";
import { forgetPassword } from "../../_services";

export function ForgetPassword() {
  const navigate = useNavigate();

  // forget password start
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onForgetSubmit = async (data) => {
    await forgetPassword(data);
  };

  // navigate to new password start
  const isNavigateCnf = useSelector((state) => state.forgetPassword.err);
  if (isNavigateCnf === alertConstant.VERIFICATION_SENT) {
    navigate("/new-password");
  }

  // start submit loading work
  const [loading, setLoading] = useState(false);

  const submitLoading = useSelector((state) => state.forgetPassword.loading);

  useEffect(() => {
    if (submitLoading === true) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [submitLoading]);

  return (
    <div>
      <div className="forgot-wrapper">
        <div className="wrapperContainer">
          <h2 className="sarina mb-5 text-center">idPair</h2>
          {/* forget password section start */}
          <div className="formContainer">
            <h4 className="title text-center">Forgot your password?</h4>
            <p className="text-center">
              Enter your email, and we will send you instructions to reset your
              password.
            </p>
            <Form className="mainForm" onSubmit={handleSubmit(onForgetSubmit)}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h6>Enter your email address</h6>
                <Form.Control
                  type="text"
                  placeholder="yourname@abc.com"
                  name="email"
                  {...register("email", {
                    required: "Email is required",
                  })}
                />
                <p style={{ color: "rgb(206, 81, 81)" }}>
                  {errors.email?.message}
                </p>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Button
                  className="forgetBtn"
                  variant="primary w-100"
                  type="submit"
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" variant="light" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Form.Group>
            </Form>
          </div>

          <div>
            <hr className="my-4" />

            <div className="mb-3 d-flex justify-content-center">
              <Link className="ms-2 link" to="/login">
                Back to Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
