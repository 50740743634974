import { forgetPassword, userConstant } from "../_constants";

const initialState = {
  loading: false,
  user: {},
  err: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case userConstant.LOGOUT:
      return initialState;
    case forgetPassword.FORGET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case forgetPassword.FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.user,
        err: "",
      };
    case forgetPassword.FORGET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        user: {},
        err: action.err,
      };
    default:
      return state;
  }
};
