import "./registeredUsersTable.css";

import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import { logger } from "../../../_helpers";
import { getOrgUsers } from "../../../_services";

const columns = [
  {
    id: 1,
    name: "Email",
    selector: (row) => row.Email,
    minWidth: "1",
    left: true,
  },
  {
    id: 2,
    name: "Name",
    selector: (row) =>
      row.FirstName ? `${row.FirstName} ${row.LastName}` : "N/A",
    minWidth: "1",
    left: true,
  },
  {
    id: 3,
    name: "Activated (by idPair)",
    selector: (row) => (row.Active ? "Yes" : "No"),
    minWidth: "1",
    left: true,
  },
];

export function RegisteredUsersTable() {
  const [users, setUsers] = useState([]);

  useEffect(async () => {
    const abortController = new AbortController();
    const { signal } = abortController;

    try {
      const result = await getOrgUsers({ signal });
      setUsers(result.data);
    } catch (error) {
      if (error.name !== "AbortError") {
        logger.error("Error fetching users: ", error);
      }
    }
    return () => {
      abortController.abort();
    };
  }, []);
  return (
    <div className="registeredUsersTable">
      <DataTable
        columns={columns}
        data={users}
        defaultSortFieldId={1}
        responsive
        // Header
        fixedHeader
        fixedHeaderScrollHeight="80vh"
        persistTableHead
      />
    </div>
  );
}
