import { alertConstant } from "../_constants";
import { TOAST_ERROR } from "../_helpers";

export const alertMessage = (toast) => {
  return {
    type: alertConstant.ALERT,
    payload: toast,
  };
};

export const clearAlertMessage = () => {
  return {
    type: alertConstant.CLEAR,
  };
};

export const alertFetchFailed = () => {
  return {
    type: alertConstant.ALERT,
    payload: {
      message: alertConstant.FETCH_FAILED,
      type: TOAST_ERROR,
    },
  };
};
