import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { AsideLeft, MainHeader } from "../../_components";
import { getSelectedTimePeriod } from "../../_helpers";
import AsideRight from "./asideRight";

export function Dashboard() {
  const location = useLocation();
  const [isActive, setActive] = useState("false");
  const handleIsActive = (bool) => {
    setActive(bool);
  };
  const previousPeriodRef = useRef(null);

  // Check if there is a selected period in the URL - if not, redirect with the selected/default period
  const period = new URLSearchParams(location.search).get("period");
  if (period === null) {
    // Try to get the period from the local storage
    const selectedPeriod = getSelectedTimePeriod();
    if (selectedPeriod !== null) {
      window.location.href = `/dashboard?period=${selectedPeriod.param}`;
    } else {
      window.location.href = "/dashboard?period=7_days"; // default period
    }
  }

  // Check if the URL is updates with a new period and reload the page
  useEffect(() => {
    const currentPeriod = new URLSearchParams(location.search).get("period");
    const previousPeriod = previousPeriodRef.current;

    if (previousPeriod !== null && currentPeriod !== previousPeriod) {
      // If the period has changed, reload the page
      window.location.reload();
    }

    // Update the previous period ref to the current period
    previousPeriodRef.current = currentPeriod;
  }, [location.search]);

  return (
    <div>
      <MainHeader />
      <div className={`wrapper ${isActive ? "" : "open"}`}>
        <AsideLeft />
        <AsideRight
          handleIsActive={handleIsActive}
          className="dashboard-wrapper"
        />
      </div>
    </div>
  );
}
