import "./asideLeft.css";

import React from "react";
import Icon from "react-icons-kit";
import {
  eye,
  gear,
  lightbulbO,
  newspaperO,
  pieChart,
  questionCircle,
  ticket,
} from "react-icons-kit/fa";
import { Navigation } from "react-minimal-side-navigation";
import { useLocation, useNavigate } from "react-router-dom";

export function AsideLeft() {
  const location = useLocation();
  const navigate = useNavigate();
  const pieChartIcon = () => <Icon size={20} icon={pieChart} />;
  // const cogIcon = () => <Icon size={20} icon={cog} />;
  const lightbulbOIcon = () => <Icon size={20} icon={lightbulbO} />;
  const ticketIcon = () => <Icon size={20} icon={ticket} />;
  const problemIcon = () => <Icon size={20} icon={eye} />;
  const questionCircleIcon = () => <Icon size={20} icon={questionCircle} />;
  const gearIcon = () => <Icon size={20} icon={gear} />;
  const newspaperIcon = () => <Icon size={20} icon={newspaperO} />;
  return (
    <div className="asideLeft d-flex flex-column">
      <Navigation
        // use your own router's api to get pathname
        activeItemId={location.pathname}
        onSelect={({ itemId }) => {
          navigate(itemId);
        }}
        items={[
          {
            title: "Overview",
            itemId: "/",
            elemBefore: pieChartIcon,
          },
          {
            title: "Registrations",
            itemId: "/registrations",
            elemBefore: ticketIcon,
          },
          {
            title: "Risk Players",
            itemId: "/risk-accounts",
            elemBefore: problemIcon,
          },
          {
            title: "All Players",
            itemId: "/players",
            elemBefore: lightbulbOIcon,
          },
          {
            title: "Reports",
            itemId: "/reports",
            elemBefore: newspaperIcon,
          },
          {
            title: "Settings",
            itemId: "/admin",
            elemBefore: gearIcon,
          },
          {
            title: "FAQ",
            itemId: "/faq",
            elemBefore: questionCircleIcon,
          },
        ]}
      />
    </div>
  );
}
