import "./playerDemographicInfo.css";

import React from "react";
import { Col, Row } from "react-bootstrap";

import { toAge, toDateOnly, toGender } from "../../_helpers";

export function PlayerDemographicInfo({ player }) {
  return (
    <Row>
      <Col sm={12}>
        <Col sm={12}>
          <div className="demographic-info">
            {player?.DemographicInfo?.BirthYear && (
              <div>
                Age {toAge(player.DemographicInfo.BirthYear)}
                {player.DemographicInfo.Gender !== undefined && (
                  <>
                    {", "}
                    {toGender(player.DemographicInfo.Gender)}
                  </>
                )}
              </div>
            )}
            {player?.DemographicInfo?.AccountOpenDate && (
              <div>
                Account created on{" "}
                {toDateOnly(player.DemographicInfo.AccountOpenDate)}
              </div>
            )}
          </div>
        </Col>
      </Col>
    </Row>
  );
}
