import { createContext, React, useMemo, useState } from "react";

import { notEquals } from "../_helpers";

export const OrgRegionContext = createContext();

export function OrgRegionProvider({ children }) {
  const [org, setOrg] = useState(null);
  const [region, setRegion] = useState(null);

  const updateOrg = (newOrg) => {
    if (notEquals(org, newOrg)) {
      setOrg(newOrg);
    }
  };

  const updateRegion = (newRegion) => {
    if (notEquals(region, newRegion)) {
      setRegion(newRegion);
    }
  };

  const value = useMemo(
    () => ({ org, updateOrg, region, updateRegion }),
    [org, updateOrg, region, updateRegion]
  );

  return (
    <OrgRegionContext.Provider value={value}>
      {children}
    </OrgRegionContext.Provider>
  );
}
