export const alertConstant = {
  ALERT: "ALERT_MESSAGE",
  CLEAR: "CLEAR_ALERT",
  FETCH_FAILED: "Error occurred while fetching data.",
  LOGIN_SUCCESSFUL: "Logged in successfully.",
  LOGOUT_SUCCESSFUL: "Logout successful.",
  NEW_PASSWORD_REQUIRED: "Please change your password.",
  newUserDetailsSentMsg: (username) =>
    `New password sent to ${username}. User must be activated by administrators before they can login.`,
  PASSWORD_RESET_FAILURE: "Failed to reset password",
  PASSWORD_RESET_SUCCESS:
    "Password reset successfully. Please login with your new password.",
  REGISTRATION_CONFIRMED: "Registration confirmed.",
  VERIFICATION_SENT: "Verification code sent.",
};
