import "./orgRegionNavDropdown.css";

import React, { useContext, useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import { OrgRegionContext } from "../../_contexts";
import {
  getOperators,
  getOrg,
  getSelectedOperator,
  getSelectedOperatorRegion,
  setCurrentRegion,
  setSelectedOperator,
  setSelectedOperatorRegion,
  toStateFullName,
} from "../../_helpers";

function formatOperatorRegion(operator, region) {
  if (operator !== null && region !== null) {
    const stateFullName = toStateFullName(region.State);
    return `${operator.Name} - ${stateFullName}`;
  }
  return "";
}

function sortOperators(operators) {
  // Sort by Name
  operators.sort((a, b) => a.Name.localeCompare(b.Name));

  // Sort Regions within each operator by State
  operators.forEach((operator) => {
    operator.Regions.sort((a, b) => a.State.localeCompare(b.State));
  });

  return operators;
}

export function OrgRegionNavDropdown({ isGlobal = false }) {
  const navigate = useNavigate();
  const location = useLocation();

  const { updateOrg, updateRegion } = useContext(OrgRegionContext);

  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedOrgRegion, setSelectedOrgRegion] = useState(null);

  const org = getOrg();
  const operators = sortOperators(getOperators() || []);

  const navDropdownTitle = isGlobal
    ? "Global"
    : formatOperatorRegion(selectedOrg, selectedOrgRegion);

  function findOperator(orgID) {
    return (
      operators.find((operator) => operator.ID.toString() === orgID) || null
    );
  }

  function findOperatorRegion(orgID, regionID) {
    const operator = findOperator(orgID);
    if (operator === null) return null;
    return (
      operator.Regions.find((reg) => reg.ID.toString() === regionID) || null
    );
  }

  function updateOrgRegion(operator, region) {
    // update internal state
    setSelectedOrg(operator);
    setSelectedOrgRegion(region);

    // store in local storage
    setSelectedOperator(operator);
    setSelectedOperatorRegion(region);

    // update shared context
    updateOrg(operator);
    updateRegion(region);

    // update formatting for mapping of meta ids
    setCurrentRegion(region.State);

    // Update URL params
    const params = new URLSearchParams(location.search);
    params.set("org", operator?.ID);
    params.set("region", region?.ID);
    navigate(`?${params.toString()}`);
  }

  const handleOperatorRegionClick = (operator, region) => {
    updateOrgRegion(operator, region);
  };

  useEffect(() => {
    if (isGlobal) return;

    let operator;
    let region;

    const params = new URLSearchParams(location.search);
    const orgIdParam = params.get("org") || null;
    const regionIdParam = params.get("region") || null;

    function areParamsValid() {
      if (orgIdParam === null || regionIdParam === null) return false;

      operator = findOperator(orgIdParam);
      region = findOperatorRegion(orgIdParam, regionIdParam);

      return operator !== null && region !== null;
    }

    if (areParamsValid()) {
      operator = findOperator(orgIdParam);
      region = findOperatorRegion(orgIdParam, regionIdParam);
    } else {
      operator = getSelectedOperator();
      region = getSelectedOperatorRegion();
    }

    updateOrgRegion(operator, region);
  }, [window.location.search]);

  return (
    <NavDropdown
      title={navDropdownTitle}
      id="org-dropdown"
      align="end"
      menuVariant="dark"
      className="nav-dropdown"
    >
      {operators.map((operator, operatorIndex) => (
        <>
          {operatorIndex > 0 && org.Role === "admin" && <NavDropdown.Divider />}
          {operator.Regions.map((region) => (
            <NavDropdown.Item
              key={`${operator.ID}-${region.ID}`}
              onClick={() => handleOperatorRegionClick(operator, region)}
              className={
                !isGlobal &&
                operator.ID === selectedOrg?.ID &&
                region.ID === selectedOrgRegion?.ID
                  ? "nav-dropdown-selected"
                  : ""
              }
              disabled={isGlobal}
            >
              {formatOperatorRegion(operator, region)}
            </NavDropdown.Item>
          ))}
        </>
      ))}
    </NavDropdown>
  );
}
