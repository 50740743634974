import "./cases.css";

import React, { useContext, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { PlayerCaseNotesTable } from "../../_components";
import { OrgRegionContext } from "../../_contexts";
import { toHumanDate } from "../../_helpers";
import {
  getPlayerCase,
  getPlayerCaseNotes,
  orgPlayerCaseClose,
} from "../../_services";
import { useFetchData } from "../../_services/dataServices";
import NewCaseNoteModal from "./NewCaseNoteModal";

export function AsideRight({ handleIsActive, playerId, caseId }) {
  const handleActive = (bool) => {
    handleIsActive(bool);
  };

  const { org, region } = useContext(OrgRegionContext);

  const { data: caseResponse, refetch: refetchCase } = useFetchData({
    fetchFunction: getPlayerCase,
    args: {
      orgID: org?.ID,
      orgPlayerID: playerId,
      regionID: region?.ID,
      orgPlayerCaseID: caseId,
    },
    dependencies: [org, region, playerId],
    required: [org, region],
  });

  const { data: notesResponse, refetch: refetchNotes } = useFetchData({
    fetchFunction: getPlayerCaseNotes,
    args: {
      orgID: org?.ID,
      orgPlayerID: playerId,
      regionID: region?.ID,
      orgPlayerCaseID: caseId,
    },
    dependencies: [org, region, playerId],
    required: [org, region],
  });

  const { OrgPlayerCase: orgCase } = caseResponse || {};
  const { OrgPlayerCaseNotes: orgCaseNotes } = notesResponse || {};

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    refetchNotes();
  };

  const handleCloseButton = async () => {
    await orgPlayerCaseClose({
      orgID: org?.ID,
      orgPlayerID: playerId,
      regionID: region?.ID,
      orgPlayerCaseID: caseId,
    });
    refetchCase();
  };

  return (
    <div className="asideRight">
      <div className="title">
        <h2>{orgCase?.Title}</h2>
      </div>

      <Link to={`/player/${playerId}`}>Back to Player Profile</Link>

      <Row>
        <div className="asideRight">
          <NewCaseNoteModal
            className="modalMain"
            backdrop="static"
            show={showModal}
            onHide={handleCloseModal}
            orgPlayerID={playerId}
            orgPlayerCaseID={caseId}
          />
        </div>
        <Col sm={12}>
          <div className="case-info">
            <div>
              <span>Case Type: </span>
              <span style={{ fontWeight: "bold" }}>{orgCase?.CaseType}</span>
            </div>
            <div>
              <span>Created on </span>
              <span style={{ fontWeight: "bold" }}>
                {toHumanDate(orgCase?.Created)}
              </span>
              <span> by </span>
              <span style={{ fontWeight: "bold" }}>
                {orgCase?.User.FirstName} {orgCase?.User.LastName}
              </span>
            </div>
            {orgCase?.Closed && (
              <div>
                <span>Closed on </span>
                <span style={{ fontWeight: "bold" }}>
                  {toHumanDate(orgCase?.Closed)}
                </span>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <div className="header">Notes</div>
        <Col sm={12}>
          <Card>
            <PlayerCaseNotesTable notes={orgCaseNotes} />
          </Card>
        </Col>
      </Row>
      <Row>
        <div className="topmargin d-flex align-items-centermt-5 justify-content-left">
          <Button
            className="signup"
            variant="danger"
            onClick={handleCloseButton}
            disabled={orgCase?.Closed !== undefined}
          >
            Close Case
          </Button>
          <span style={{ marginRight: "0.5rem" }} />
          <Button
            className="signup"
            variant="info"
            onClick={handleShowModal}
            disabled={orgCase?.Closed !== undefined}
          >
            Add Note
          </Button>
        </div>
      </Row>
    </div>
  );
}
