import "./playerStatsAggregationsTable.css";

import React from "react";
import DataTable from "react-data-table-component";

import { toCurrency, toStateFullName } from "../../../_helpers";

const columns = [
  {
    id: 1,
    name: "OPERATOR",
    selector: (row) => row.Org.Name,
    sortable: true,
    reorder: true,
    minWidth: "1",
  },
  {
    id: 2,
    name: "REGION",
    selector: (row) => toStateFullName(row.Region.State),
    sortable: true,
    reorder: true,
    minWidth: "1",
  },
  {
    id: 3,
    name: "TURNOVER",
    selector: (row) => toCurrency(row.TotalSportsBetTurnOver) || toCurrency(0),
    sortable: true,
    reorder: true,
    minWidth: "1",
  },
  {
    id: 4,
    name: "PLAYER WIN",
    selector: (row) =>
      toCurrency(row.TotalSportsBetGrossBalance) || toCurrency(0),
    sortable: true,
    reorder: true,
    minWidth: "1",
  },
  {
    id: 5,
    name: "# BETS",
    selector: (row) => row.TotalSportsBetBetsCount || 0,
    sortable: true,
    reorder: true,
    minWidth: "1",
  },
  {
    id: 6,
    name: "BONUS TURNOVER",
    selector: (row) =>
      toCurrency(row.TotalSportsBetBonusTurnOver) || toCurrency(0),
    sortable: true,
    reorder: true,
    minWidth: "1",
  },
  {
    id: 7,
    name: "BONUS WIN",
    selector: (row) =>
      toCurrency(row.TotalSportsBetBonusGrossBalance) || toCurrency(0),
    sortable: true,
    reorder: true,
    minWidth: "1",
  },
  {
    id: 8,
    name: "# BONUS BETS",
    selector: (row) => row.TotalSportsBetBonusBetsCount || 0,
    sortable: true,
    reorder: true,
    minWidth: "1",
  },
];

export function PlayerStatsAggregationsTable({ totals }) {
  return (
    <div className="playerDetailsTable">
      <DataTable
        columns={columns}
        data={totals}
        // Styling
        fixedHeader
        fixedHeaderScrollHeight="80vh"
        highlightOnHover
        persistTableHead
      />
    </div>
  );
}
