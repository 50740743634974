import "./faq.css";

import React from "react";

export function AsideRight() {
  return (
    <div className="asideRight">
      <div className="title">
        <h2>FAQ</h2>
      </div>
      <div className="faq">
        <div className="faq-item">
          <div className="faq-question">
            <strong>Q: What does the Risk Account page describe?</strong>
          </div>
          <div className="faq-answer">
            <p>
              A: The Risk Account page contains get any player with a RG score
              &gt; 0 in the selected time period. Players are ordered by total
              RG score.
            </p>
          </div>
        </div>
        <div className="faq-item">
          <div className="faq-question">
            <strong>Q: How is the total RG score calculated?</strong>
          </div>
          <div className="faq-answer">
            <p>
              A: The scoring algorithm has five different categories used when
              identifying risk players:
            </p>
            <ul>
              <li>Losing</li>
              <li>Betting Behavior</li>
              <li>Time Engagement</li>
              <li>Risk Tolerance</li>
              <li>Chasing</li>
            </ul>
            <p>
              Each category contributes with maximum of 3p to the total which
              means that a player can be scored a maximum of <b>15p</b> per day.
            </p>
          </div>
        </div>
        <div className="faq-item">
          <div className="faq-question">
            <strong>
              Q: How can I see the breakdown of the RG total score into each
              category?
            </strong>
          </div>
          <div className="faq-answer">
            <p>
              A: The player details page contains a graph in which one can see
              the breakdown of how each category contributes to the total score.
            </p>
          </div>
        </div>
        <div className="faq-item">
          <div className="faq-question">
            <strong>Q: What does the RG losing category describe?</strong>
          </div>
          <div className="faq-answer">
            <p>
              A: If the gross balance for a player is less than a personalized
              threshold value the algorithm will score 1 or higher. There are
              three threshold values used and the highest will yield a score of
              3p.
            </p>
          </div>
        </div>
        <div className="faq-item">
          <div className="faq-question">
            <strong>
              Q: What does the RG betting behavior category describe?
            </strong>
          </div>
          <div className="faq-answer">
            <p>
              A: In this category the algorithm looks for number of bets per
              period; yesterday or the last three days. If either of the periods
              surpass the thresholds, it will score accordingly.
            </p>
          </div>
        </div>
        <div className="faq-item">
          <div className="faq-question">
            <strong>
              Q: What does the RG time engagement category describe?
            </strong>
          </div>
          <div className="faq-answer">
            <p>
              A: This category looks at the number of interactions per day
              broken into hourly segments.
            </p>
          </div>
        </div>
        <div className="faq-item">
          <div className="faq-question">
            <strong>
              Q: What does the RG risk tolerance category describe?
            </strong>
          </div>
          <div className="faq-answer">
            <p>
              A: Risk tolerance looks at five periods to determine if the risk
              appetite increases; yesterday, 7, 14, 21, 28, and 35 days ago.
            </p>
          </div>
        </div>
        <div className="faq-item">
          <div className="faq-question">
            <strong>Q: What does the RG chasing category describe?</strong>
          </div>
          <div className="faq-answer">
            <p>
              A: This category looks at the players behavior with regards to
              wins and losses. If a win/loss trigger a &apos;chasing&apos;
              behavior the algorithm will score accordingly.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
