import "./NotFound.css";

import React from "react";
import { Button, Image } from "react-bootstrap";
import { Icon } from "react-icons-kit";
import { ic_keyboard_arrow_left as icKeyboardArrowLeft } from "react-icons-kit/md";
import { Link } from "react-router-dom";

import Error404 from "../../images/Error404.jpg";

export function NotFound() {
  return (
    <div className="error">
      <div className="error404">
        <Image src={Error404} fluid />
        <Link className="link" to="/">
          <Button variant="primary" className="d-flex align-items-center">
            <Icon size={24} icon={icKeyboardArrowLeft} />
            Back to Dashboard
          </Button>
        </Link>
      </div>
    </div>
  );
}
