import "react-datepicker/dist/react-datepicker.css";
import "./header.css";

import React, { useState } from "react";
import { Button, Nav, Navbar, NavDropdown } from "react-bootstrap";
import Icon from "react-icons-kit";
import { navicon, powerOff } from "react-icons-kit/fa";
import { Link, useNavigate } from "react-router-dom";

import { getUser } from "../../_helpers";
import { userLogout } from "../../_services";
import logo from "../../images/idpair_round_logo.png";
import { OrgRegionNavDropdown } from "../orgRegionNavDropdown/orgRegionNavDropdown";
import { TimePeriodNavDropdown } from "../timePeriodNavDropdown/timePeriodNavDropdown";

export function MainHeader(props) {
  const navigate = useNavigate();
  const [isActive, setActive] = useState("false");
  const { handleActive, isGlobal } = props;

  const handleToggle = () => {
    setActive(!isActive);
    handleActive(!isActive);
  };

  const user = getUser();

  // Handle logout
  const logoutUser = async (e) => {
    e.preventDefault();
    userLogout();
    navigate("/");
    navigate("/login");
  };

  return (
    <div className="main-header d-flex justify-content-between align-items-center">
      <Button className="toggle-button" onClick={handleToggle}>
        <Icon size={24} icon={navicon} />
      </Button>

      <Link className="link logo" to="/">
        <span>
          <img src={logo} alt="logo" />
        </span>
        <span>idPair</span>
      </Link>

      <Navbar bg="#363740;" expand="lg" style={{ height: "50" }}>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto navbar-nav">
            <TimePeriodNavDropdown />

            <OrgRegionNavDropdown isGlobal={isGlobal} />

            <NavDropdown
              title={`${user?.FirstName} ${user?.LastName}`}
              id="account-nav-dropdown"
              align="end"
              menuVariant="dark"
              className="nav-dropdown"
            >
              <NavDropdown.Item
                className="logoutBtn"
                onClick={(e) => logoutUser(e)}
              >
                Logout &nbsp;&nbsp;&nbsp;
                <Icon size={20} icon={powerOff} />
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
