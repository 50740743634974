import "./createRule.css";

import React from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Icon } from "react-icons-kit";
import { angleRight } from "react-icons-kit/fa/angleRight";

import { MainHeader } from "../../_components";

export function AsideRight(props) {
  const { handleIsActive } = props;
  const handleActive = (bool) => {
    handleIsActive(bool);
  };

  return (
    <div className="asideRight">
      <MainHeader handleActive={handleActive} name="Create Rule" />

      <Row className="d-flex row">
        <Card className="bigCard p-5 mb-4">
          <Row className="mt-5 mb-5">
            <Col lg={6} md={6} sm={12}>
              <h6>Rule name</h6>
              <InputGroup>
                <Form.Control />
              </InputGroup>
            </Col>
          </Row>
          <Row className="mt-5 mb-5">
            <Col lg={3} md={4} sm={5}>
              <h6>Category</h6>
              <InputGroup>
                <Form.Select>
                  <option>Select Customer Type</option>
                  <option>option 1</option>
                  <option>option 2</option>
                  <option>option 3</option>
                  <option>option 4</option>
                </Form.Select>
              </InputGroup>
            </Col>
            <Col lg={3} md={4} sm={5}>
              <h6>Action</h6>
              <InputGroup>
                <Form.Select>
                  <option>Select Action</option>
                  <option>Action 1</option>
                  <option>Action 2</option>
                  <option>Action 3</option>
                  <option>Action 4</option>
                </Form.Select>
              </InputGroup>
            </Col>
            <Col lg={3} md={4} sm={2}>
              <h6>Category</h6>
              <Form.Group>
                <Form.Check
                  value="high"
                  type="radio"
                  aria-label="radio 1"
                  label="High"
                  name="x"
                />
                <Form.Check
                  value="normal"
                  type="radio"
                  aria-label="radio 1"
                  label="Normal"
                  className="mt-4 mb-4"
                  name="x"
                />
                <Form.Check
                  value="low"
                  type="radio"
                  aria-label="radio 1"
                  label="Low"
                  name="x"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg={12} md={12} sm={12}>
              <h6>Time period</h6>
              <div>
                <input className="datePicker" id="datePicker1" type="date" />
                <span className="iconSpan">
                  <Icon className="rightIcon" size={20} icon={angleRight} />
                </span>
                <input className="datePicker" id="datePicker2" type="date" />
              </div>
            </Col>
          </Row>
          <Row className="">
            <Col lg={6} md={6} sm={12}>
              <h6>Description</h6>
              <InputGroup>
                <Form.Control />
              </InputGroup>
            </Col>
          </Row>
          <Row className="mt-3  mb-3">
            <Col lg={6} md={6} sm={12}>
              <Form.Group>
                <Form.Check
                  value=""
                  type="checkbox"
                  label="Alert Me When Triggered"
                  name="x"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col lg={4} md={4} sm={5}>
              <Button className="cancelBtn" size="lg">
                Cancel
              </Button>
            </Col>
            <Col lg={3} md={3} sm={2} />
            <Col lg={4} md={4} sm={5}>
              <Button className="createBtn" size="lg">
                Create
              </Button>
            </Col>
          </Row>
        </Card>
      </Row>
    </div>
  );
}
