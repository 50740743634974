import "./Login.css";

import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { alertConstant, userConstant } from "../../_constants";
import { getAccessToken, isTokenValid } from "../../_helpers";
import { userLogin } from "../../_services";
import ChangePasswordModal from "./ChangePasswordModal";

export function Login() {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const accessToken = getAccessToken();

  const loginStatusMsg = useSelector((state) => state.user.err);

  useEffect(() => {
    if (loginStatusMsg === alertConstant.NEW_PASSWORD_REQUIRED) {
      setModalShow(true);
    } else if (
      loginStatusMsg === userConstant.LOGIN_SUCCESS &&
      isTokenValid(accessToken)
    ) {
      navigate("/dashboard");
    }
  }, [loginStatusMsg, accessToken]);

  const onSubmit = async (data) => {
    await userLogin(data);
  };

  return (
    <div>
      <ChangePasswordModal
        className="modalMain"
        backdrop="static"
        onHide={() => setModalShow(false)}
        show={modalShow}
      />

      <div className="login-wrapper">
        <div className="login-container">
          <div className="login-left">
            <div className="top">
              <h2 className="sarina">idPair</h2>
            </div>
            <div className="center">
              <h2 className="sarina">Portal</h2>
            </div>
            <div className="center">
              <h5 className="sarina-small">v1.3.0 - July 15, 2024</h5>
            </div>
          </div>
          <div className="login-right">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <h3 className="title text-center">Login</h3>
              <Form.Group className="mb-3 mt-5" controlId="formBasicUsername">
                <h6>Enter your Email</h6>
                <Form.Control
                  type="text"
                  placeholder="user@domain.com"
                  name="email"
                  {...register("email", {
                    required: "Email is required",
                  })}
                />
                <p style={{ color: "rgb(206, 81, 81)" }}>
                  {errors.email?.message}
                </p>
              </Form.Group>
              <Form.Group className="mb-3 mt-4" controlId="formBasicPassword">
                <h6>Enter your Password</h6>
                <Form.Control
                  type="password"
                  placeholder="Enter your Password"
                  name="password"
                  {...register("password", {
                    required: "Password is required",
                    pattern: {
                      value:
                        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
                      message:
                        "Password should be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one digit and one special character",
                    },
                  })}
                />
                <p style={{ color: "rgb(206, 81, 81)" }}>
                  {errors.password?.message}
                </p>
              </Form.Group>
              <Form.Group
                className="mb-3 d-flex align-items-center"
                controlId="formBasicSubmit"
              >
                <Button className="lgnBtn" type="submit">
                  Login
                </Button>
                <Link className="ms-auto link" to="/forget-password">
                  Forgot Password?
                </Link>
              </Form.Group>
              <hr className="my-4" />
              <div className="copy-right">
                &copy; 2023 <a href="https://www.idpair.com">idPair</a>. All
                rights reserved.
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
