import * as Plot from "@observablehq/plot";
import React, { useEffect, useRef } from "react";

import { decimalToPercentage, toDateOnly } from "../../../_helpers";
import { areDatesEqual } from "../../../_helpers/dateUtils";

function findScore(scores, date) {
  const score = scores.find((s) => areDatesEqual(s.ScoreDate, date));
  return score;
}

function findFirstScore(scores) {
  const score = scores[0];
  if (!score) return null;
  return new Date(score.ScoreDate);
}

function emptyScore(date) {
  return {
    RgBettingBehaviorScore: 0,
    RgChasingScore: 0,
    RgEngagementTimeScore: 0,
    RgLossesScore: 0,
    RgRiskToleranceScore: 0,
    ScoreDate: date.toISOString(),
  };
}

function fillEmptyValues(from, to, scores) {
  if (!from || !to) return [];

  const fromDate = from === "*" ? findFirstScore(scores) : new Date(from);
  const toDate = new Date(to);

  const allScores = [];
  const currentDate = new Date(fromDate);

  while (currentDate < toDate) {
    const currentScore =
      findScore(scores, currentDate) || emptyScore(new Date(currentDate));

    allScores.push(currentScore);

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return allScores;
}

const TotalMaxScore = 15;

function transformScores(scores) {
  const transformedScores = [];
  scores.forEach((s) => {
    transformedScores.push({
      ScoreDate: new Date(s.ScoreDate),
      ScoreName: "Betting Behavior Score",
      ScoreValue: s.RgBettingBehaviorScore / TotalMaxScore,
    });
    transformedScores.push({
      ScoreDate: new Date(s.ScoreDate),
      ScoreName: "Chasing Score",
      ScoreValue: s.RgChasingScore / TotalMaxScore,
    });
    transformedScores.push({
      ScoreDate: new Date(s.ScoreDate),
      ScoreName: "Engagement Time Score",
      ScoreValue: s.RgEngagementTimeScore / TotalMaxScore,
    });
    transformedScores.push({
      ScoreDate: new Date(s.ScoreDate),
      ScoreName: "Losses Score",
      ScoreValue: s.RgLossesScore / TotalMaxScore,
    });
    transformedScores.push({
      ScoreDate: new Date(s.ScoreDate),
      ScoreName: "Risk Tolerance Score",
      ScoreValue: s.RgRiskToleranceScore / TotalMaxScore,
    });
  });

  return transformedScores;
}

function sumScoresByDate(scores) {
  const transformedScores = [];
  scores.forEach((s) => {
    transformedScores.push({
      ScoreDate: new Date(s.ScoreDate),
      TotalScore:
        (s.RgBettingBehaviorScore +
          s.RgChasingScore +
          s.RgEngagementTimeScore +
          s.RgLossesScore +
          s.RgRiskToleranceScore) /
        TotalMaxScore,
    });
  });

  return transformedScores;
}

export function PlayerScoresGraph({ from, to, scores }) {
  const allScores = fillEmptyValues(from, to, scores);
  const transformedScores = transformScores(allScores);
  const sumScores = sumScoresByDate(allScores);

  const graphDivRef = useRef();

  useEffect(() => {
    if (transformedScores === undefined) return;

    const plot = Plot.plot({
      style: { backgroundColor: "white" },
      width: graphDivRef.current.offsetWidth,
      height: 200,
      color: { legend: true },
      y: { label: "Score %", percent: true, grid: true, domain: [0, 100] },
      x: { label: null, interval: "day" },
      marks: [
        // Stacked bars
        Plot.rectY(transformedScores, {
          title: (s) =>
            [
              toDateOnly(s.ScoreDate),
              s.ScoreName,
              decimalToPercentage(s.ScoreValue),
            ].join("\n"),
          x: "ScoreDate",
          y: "ScoreValue",
          fill: "ScoreName",
          tip: true,
        }),
        // Mean Line
        Plot.ruleY(
          sumScores,
          Plot.groupZ(
            { y: "mean" },
            {
              y: "TotalScore",
              stroke: "#363740",
              strokeWidth: 1.5,
              strokeDasharray: "3,4",
              opacity: 0.75,
            }
          )
        ),
        // Rule
        Plot.ruleY([0]),
      ],
    });

    graphDivRef.current.append(plot);

    // eslint-disable-next-line consistent-return
    return () => plot.remove();
  }, [transformedScores]);

  return (
    <div style={{ marginTop: "30px" }}>
      <h4>Responsible Gaming Scores</h4>
      <div ref={graphDivRef} />
    </div>
  );
}
