import { React, useContext } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { OrgRegionContext } from "../../_contexts";
import { orgPlayerCaseCreation } from "../../_services";

export default function NewCaseModal({ onHide, orgPlayerID, ...props }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { org, region } = useContext(OrgRegionContext);

  const closeModal = () => {
    onHide();
  };

  // credential and validations work
  const onSubmit = async (formData) => {
    await orgPlayerCaseCreation({
      ...formData,
      orgID: org?.ID,
      regionID: region?.ID,
      orgPlayerID,
    });
    reset();
    closeModal();
  };

  return (
    <Modal {...props}>
      <Modal.Header>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <Modal.Title>New Case</Modal.Title>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Label>Case Type</Form.Label>
            <Form.Select
              {...register("caseType", { required: "Case Type is required" })}
            >
              <option value="">Select Case Type</option>
              <option value="AMLAntiMoneyLaunderingConcerns">
                AML (Anti-Money Laundering) Concerns
              </option>
              <option value="CommunicationBehavior">
                Communication Behavior
              </option>
              <option value="ComplaintsAndServiceIssues">
                Complaints and Service Issues
              </option>
              <option value="DataBreachPrivacyConcern">
                Data Breach/Privacy Concern
              </option>
              <option value="ResponsibleGamingConcerns">
                Responsible Gaming Concerns
              </option>
              <option value="SelfExclusionRequest">
                Self-Exclusion Request
              </option>
              <option value="SuspectedFraudulentActivity">
                Suspected Fraudulent Activity
              </option>
              <option value="TermsOfServiceViolation">
                Terms of Service Violation
              </option>
            </Form.Select>
            <p>
              <span style={{ color: "rgb(206, 81, 81)" }}>
                {errors.content?.message}
              </span>
            </p>
          </Form.Group>

          <Form.Group className="mb-3 mt-5">
            <h6>Title</h6>
            <Form.Control
              type="text"
              placeholder="e.g. 'Title'"
              name="name"
              {...register("title", {
                required: "Title is required",
              })}
            />
            <p>
              <span style={{ color: "rgb(206, 81, 81)" }}>
                {errors.title?.message}
              </span>
            </p>
          </Form.Group>

          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Button className="signup" type="submit">
              Create
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
