import "./playerActivity.css";

import React, { useContext, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";

import {
  PeriodDropdown,
  PlayerActiveHoursGraph,
  PlayerScoresGraph,
  PlayerStatsAggregationsTable,
  PlayerStatsGraph,
} from "../../_components";
import { OrgRegionContext } from "../../_contexts";
import {
  getPlayerScores,
  getPlayerStats,
  getPlayerStatsAggregation,
} from "../../_services";
import { useFetchData } from "../../_services/dataServices";
import { PlayerStatsCards } from "./playerStatsCards";

export function PlayerActivity({ player }) {
  const { org, region } = useContext(OrgRegionContext);

  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const fromParam = from === "*" ? null : from;
  const toParam = to;

  const handlePeriodChange = (fromValue, toValue) => {
    setFrom(fromValue);
    setTo(toValue);
  };

  const { data: statsAggregationResponse } = useFetchData({
    fetchFunction: getPlayerStatsAggregation,
    args: {
      orgID: org?.ID,
      regionID: region?.ID,
      orgPlayerID: player?.OrgPlayerID,
      statsFrom: fromParam,
      statsTo: toParam,
    },
    dependencies: [org, region, player, from, to],
    required: [org, region, player, from, to],
  });

  const playerStatsAggregation = statsAggregationResponse?.Stats || [];

  const { data: statsResponse } = useFetchData({
    fetchFunction: getPlayerStats,
    args: {
      orgID: org?.ID,
      regionID: region?.ID,
      orgPlayerID: player?.OrgPlayerID,
      statsFrom: fromParam,
      statsTo: toParam,
    },
    dependencies: [org, region, player, from, to],
    required: [org, region, player, from, to],
  });

  const { data: scoresResponse } = useFetchData({
    fetchFunction: getPlayerScores,
    args: {
      orgID: org?.ID,
      regionID: region?.ID,
      orgPlayerID: player?.OrgPlayerID,
      scoreFrom: fromParam,
      scoreTo: toParam,
    },
    dependencies: [org, region, player, from, to],
    required: [org, region, player, from, to],
  });

  const playerStats = statsResponse?.Stats || [];
  const playerScores = scoresResponse?.Scores || [];

  return (
    <div style={{ paddingBottom: "50px" }}>
      <div className="my-4 d-flex align-items-end justify-content-end">
        <PeriodDropdown onPeriodChange={handlePeriodChange} />
      </div>

      <PlayerStatsCards playerStatsAggregation={playerStatsAggregation} />

      <Row>
        <Col sm={12}>
          <Card>
            <PlayerStatsAggregationsTable totals={playerStatsAggregation} />
          </Card>
        </Col>
      </Row>

      <PlayerScoresGraph from={from} to={to} scores={playerScores} />

      <PlayerActiveHoursGraph from={from} to={to} stats={playerStats} />

      <PlayerStatsGraph from={from} to={to} stats={playerStats} />
    </div>
  );
}
