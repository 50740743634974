import React, { useState } from "react";

import { AsideLeft, MainHeader } from "../../_components";
import { AsideRight } from "./reportsRight";

export function Reports() {
  const [isActive, setActive] = useState("false");
  const handleIsActive = (bool) => {
    setActive(bool);
  };

  return (
    <div>
      <MainHeader />
      <div className={`wrapper ${isActive ? "" : "open"}`}>
        <AsideLeft />
        <AsideRight handleIsActive={handleIsActive} />
      </div>
    </div>
  );
}
