export const orgPlayerCaseConstant = {
  CREATE_ORG_PLAYER_CASE: "CREATE_ORG_PLAYER_CASE",
  CREATE_ORG_PLAYER_CASE_SUCCESS: "CREATE_ORG_PLAYER_CASE_SUCCESS",
  CREATE_ORG_PLAYER_CASE_FAILURE: "CREATE_ORG_PLAYER_CASE_FAILURE",
  CREATE_ORG_PLAYER_CASE_NOTE: "CREATE_ORG_PLAYER_CASE_NOTE",
  CREATE_ORG_PLAYER_CASE_NOTE_SUCCESS: "CREATE_ORG_PLAYER_CASE_NOTE_SUCCESS",
  CREATE_ORG_PLAYER_CASE_NOTE_FAILURE: "CREATE_ORG_PLAYER_CASE_NOTE_FAILURE",
  CLOSE_ORG_PLAYER_CASE: "CLOSE_ORG_PLAYER_CASE",
  CLOSE_ORG_PLAYER_CASE_SUCCESS: "CLOSE_ORG_PLAYER_CASE_SUCCESS",
  CLOSE_ORG_PLAYER_CASE_FAILURE: "CLOSE_ORG_PLAYER_CASE_FAILURE",
};
