import "./newPassword.css";

import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { newPassword } from "../../_services";

export function NewPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  // start submit loading work
  const [loading, setLoading] = useState(false);

  const submitLoading = useSelector((state) => state.forgetPassword.loading);

  useEffect(() => {
    if (submitLoading === true) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [submitLoading]);

  // navigate to login start

  const isNavigateLogin = useSelector((state) => state.user.user);
  if (isNavigateLogin === "Password reset successfully, now you may login.") {
    navigate("/login");
  }

  // new password start
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const password = useRef({});
  password.current = watch("password", "");

  const onConfirmPassSubmit = async (data) => {
    const { confirmPassword, ...rest } = data; // eslint-disable-line

    await newPassword(rest);
    navigate("/login");
  };
  // new password end

  return (
    <div>
      <div className="forgot-wrapper">
        <div className="wrapperContainer">
          <h2 className="sarina mb-5 text-center">idPair</h2>
          {/* confirm password section start */}
          <div className="formContainer">
            <h4 className="title ">Confirm your new password</h4>
            <p>
              A 6 digit verification code has been sent to your email, please
              enter it here.
            </p>
            <Form
              className="mainForm"
              onSubmit={handleSubmit(onConfirmPassSubmit)}
            >
              <Form.Group className="mb-3">
                <h6>Your email</h6>
                <Form.Control
                  type="text"
                  placeholder={email}
                  value={email}
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  {...register("email", {
                    required: "Email is required",
                  })}
                />
                <p style={{ color: "rgb(206, 81, 81)" }}>
                  {errors.email?.message}
                </p>
              </Form.Group>

              <Form.Group className="mb-3">
                <h6>Password</h6>
                <Form.Control
                  type="password"
                  placeholder="Create your new password"
                  name="password"
                  {...register("password", {
                    required: "Password is required",
                    pattern: {
                      value:
                        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
                      message:
                        "Password should be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one digit and one special character",
                    },
                  })}
                />
                <p style={{ color: "rgb(206, 81, 81)" }}>
                  {errors.password?.message}
                </p>
              </Form.Group>

              <Form.Group className="mb-3">
                <h6>Confirm Password</h6>
                <Form.Control
                  type="password"
                  placeholder="Enter your new password"
                  name="confirmPassword"
                  {...register("confirmPassword", {
                    validate: (value) =>
                      value === password.current || "Passwords do not match",
                  })}
                />
                <p style={{ color: "rgb(206, 81, 81)" }}>
                  {errors.confirmPassword?.message}
                </p>
              </Form.Group>
              <Form.Group className="mb-3">
                <h6>Verification code</h6>
                <Form.Control
                  type="text"
                  placeholder="Enter your verification code"
                  name="code"
                  {...register("code", {
                    required: "Verification code is required",
                  })}
                />
                <p style={{ color: "rgb(206, 81, 81)" }}>
                  {errors.code?.message}
                </p>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Button
                  className="forgetBtn"
                  variant="primary w-100"
                  type="submit"
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" variant="light" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Form.Group>
            </Form>
          </div>
          {/* confirm password section end */}

          <hr className="my-4" />

          <div className="mb-3 d-flex justify-content-center">
            <Link className="ms-2 link" to="/login">
              Back to Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
