import { createContext, React, useMemo, useState } from "react";

import { notEquals } from "../_helpers";

export const TimePeriodContext = createContext();

export function TimePeriodProvider({ children }) {
  const [timePeriod, setTimePeriod] = useState(null);

  const updateTimePeriod = (newTimePeriod) => {
    if (notEquals(timePeriod, newTimePeriod)) {
      setTimePeriod(newTimePeriod);
    }
  };

  const value = useMemo(
    () => ({ timePeriod, updateTimePeriod }),
    [timePeriod, updateTimePeriod]
  );

  return (
    <TimePeriodContext.Provider value={value}>
      {children}
    </TimePeriodContext.Provider>
  );
}
