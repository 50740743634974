export const getHttpAlertMessage = (err) => {
  const status =
    typeof err === "object" && err.response ? err.response.status : err;

  if (status === 401) {
    return "Your account has not been authorized. Please contact the administrator to get access.";
  }
  if (status >= 400 && status <= 499) {
    return "Incorrect user details. Please try again.";
  }
  if (status >= 500 && status <= 599) {
    return "Server error occurred. Please try again later.";
  }
  return "Something went wrong. Please contact your administrator or try again later.";
};
