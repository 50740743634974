import "./timePeriodNavDropdown.css";

import React, { useContext, useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import { TimePeriodContext } from "../../_contexts";
import {
  fromTimeParam,
  getSelectedTimePeriod,
  setSelectedTimePeriod,
  toTimeParam,
} from "../../_helpers";

const timePeriods = [
  {
    label: "7 days",
    param: "7_days",
    days: "7",
  },
  {
    label: "30 days",
    param: "30_days",
    days: "30",
  },
  {
    label: "90 days",
    param: "90_days",
    days: "90",
  },
];

function formatPeriod(timePeriod) {
  if (timePeriod === null) {
    // No time period in state - check local storage
    const tp = getSelectedTimePeriod();
    if (tp === null) {
      // No time period in state or local storage - use default
      setSelectedTimePeriod(timePeriods[0]);
      return timePeriods[0].label;
    }

    return tp.label;
  }

  return timePeriod.label;
}

export function TimePeriodNavDropdown() {
  const navigate = useNavigate();
  const location = useLocation();
  const { updateTimePeriod } = useContext(TimePeriodContext);
  const [selectedTP, setTP] = useState(null);
  const navDropdownTitle = formatPeriod(selectedTP);

  function findTimePeriod(tpParam) {
    return timePeriods.find((tp) => tp.param === tpParam) || null;
  }

  function updateState(timePeriod) {
    // Update internal state
    setTP(timePeriod);
    // Update local storage
    setSelectedTimePeriod(timePeriod);
    // Update shared context
    updateTimePeriod(timePeriod);
    // Update associated URL params
    const params = new URLSearchParams(location.search);
    const now = new Date();
    const fromTime = fromTimeParam(now, timePeriod);
    const toTime = toTimeParam(now);
    params.set("period", timePeriod.param);
    params.set("from", fromTime);
    params.set("to", toTime);
    navigate(`?${params.toString()}`);
  }

  useEffect(() => {
    let timePeriod;
    const params = new URLSearchParams(location.search);
    const timePeriodParam = params.get("period") || null;
    if (timePeriodParam === null) {
      timePeriod = getSelectedTimePeriod();
    } else {
      timePeriod = findTimePeriod(timePeriodParam);
    }

    updateState(timePeriod);
  }, [window.location.search]);

  const handleClick = (timePeriod) => {
    updateState(timePeriod);
  };

  return (
    <NavDropdown
      title={navDropdownTitle}
      id="time-period-dropdown"
      align="end"
      menuVariant="dark"
      className="nav-dropdown"
    >
      {timePeriods.map((timePeriod) => (
        <NavDropdown.Item
          key={timePeriod.days}
          onClick={() => handleClick(timePeriod)}
          className={
            selectedTP?.label === timePeriod.label
              ? "nav-dropdown-selected"
              : ""
          }
        >
          {timePeriod.label}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
}
