import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { AsideLeft, MainHeader } from "../../_components";
import { AsideRight } from "./asideRight";

export function Cases() {
  const [isActive, setActive] = useState("false");
  const handleIsActive = (bool) => {
    setActive(bool);
  };
  const { playerId } = useParams();
  const { caseId } = useParams();

  return (
    <div>
      <MainHeader />
      <div className={`wrapper ${isActive ? "" : "open"}`}>
        <AsideLeft />
        <AsideRight
          handleIsActive={handleIsActive}
          playerId={playerId}
          caseId={caseId}
        />
      </div>
    </div>
  );
}
