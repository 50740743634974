import * as Plot from "@observablehq/plot";
import React, { useEffect, useRef } from "react";

import { toDateOnly } from "../../../_helpers";
import { fillEmptyValues } from "../statsUtils.js";

function transformStats(scores) {
  const transformedStats = [];
  scores.forEach((s) => {
    transformedStats.push({
      StatisticDate: new Date(s.StatisticDate),
      ActiveHours: s.ActiveHours,
    });
  });

  return transformedStats;
}

export function PlayerActiveHoursGraph({ from, to, stats }) {
  const allStats = fillEmptyValues(from, to, stats);
  const transformedStats = transformStats(allStats);

  const graphDivRef = useRef();

  useEffect(() => {
    if (transformedStats === undefined) return;

    const plot = Plot.plot({
      style: { backgroundColor: "white" },
      width: graphDivRef.current.offsetWidth,
      height: 200,
      y: { label: "Total Active Hours", grid: true, domain: [0, 24] },
      x: { label: null, interval: "day" },
      marks: [
        // Bars
        Plot.rectY(transformedStats, {
          x: "StatisticDate",
          y: "ActiveHours",
          fill: "#445e7ad9",
        }),
        // Mean Line
        Plot.ruleY(
          transformedStats,
          Plot.groupZ(
            { y: "mean" },
            {
              y: "ActiveHours",
              stroke: "#363740",
              strokeWidth: 1.5,
              strokeDasharray: "3,4",
              opacity: 0.75,
            }
          )
        ),
        // Tip
        Plot.tip(
          transformedStats,
          Plot.pointerX({
            title: (s) =>
              [
                toDateOnly(s.StatisticDate),
                `Active Hours: ${s.ActiveHours}`,
              ].join("\n"),
            x: "StatisticDate",
            y: "ActiveHours",
          })
        ),
        // Rule
        Plot.ruleY([0]),
      ],
    });

    graphDivRef.current.append(plot);

    // eslint-disable-next-line consistent-return
    return () => plot.remove();
  }, [transformedStats]);

  return (
    <div style={{ marginTop: "30px" }}>
      <h4>Active Hours</h4>
      <div ref={graphDivRef} />
    </div>
  );
}
