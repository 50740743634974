import React, { useContext, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";

import { PlayerCasesTable } from "../../_components";
import { OrgRegionContext } from "../../_contexts";
import { getPlayerCases } from "../../_services";
import { useFetchData } from "../../_services/dataServices";
import NewCaseModal from "../cases/NewCaseModal";

export function PlayerCases({ player }) {
  const { org, region } = useContext(OrgRegionContext);

  const [showModal, setShowModal] = useState(false);

  const { data: playerCasesResponse, refetch: refetchPlayerCases } =
    useFetchData({
      fetchFunction: getPlayerCases,
      args: {
        orgID: org?.ID,
        regionID: region?.ID,
        orgPlayerID: player?.OrgPlayerID,
      },
      dependencies: [org, region, player],
      required: [org, region, player],
    });

  const { OrgPlayerCases: cases } = playerCasesResponse || {};

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    refetchPlayerCases();
  };

  return (
    <div>
      <div>
        <NewCaseModal
          className="modalMain"
          backdrop="static"
          show={showModal}
          onHide={handleCloseModal}
          orgPlayerID={player?.OrgPlayerID}
        />
      </div>

      <Row>
        <div className="header">Open Cases</div>
        <Col sm={12}>
          <Card>
            <PlayerCasesTable
              cases={cases?.filter((c) => !c.Closed)}
              playerID={player?.OrgPlayerID}
              open
            />
          </Card>
        </Col>
        <div className="topmargin d-flex align-items-centermt-5 justify-content-left">
          <Button className="signup" variant="info" onClick={handleShowModal}>
            New Case
          </Button>
        </div>
      </Row>
      <Row>
        <div className="header">Closed Cases</div>
        <Col sm={12}>
          <Card>
            <PlayerCasesTable
              cases={cases?.filter((c) => c.Closed)}
              playerID={player?.OrgPlayerID}
              open={false}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}
