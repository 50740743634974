import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.min.css";

// import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import { OrgRegionProvider, TimePeriodProvider } from "../_contexts";
import { getAccessToken, idMap, isTokenValid, logger } from "../_helpers";
import { clearAlert } from "../_services/alertServices";
import {
  Admin,
  Cases,
  CreateRule,
  Dashboard,
  DataSearch,
  FAQ,
  ForgetPassword,
  Login,
  NewPassword,
  NotFound,
  PlayerDetails,
  PlayersDashboard,
  Registrations,
  Reports,
  RiskAccounts,
  SignUp,
  // RiskSetting,
} from "../pages";

function ProtectedRoute() {
  const token = getAccessToken();
  const [validToken, setValidToken] = useState(isTokenValid(token));

  useEffect(() => {
    const interval = setInterval(() => {
      const currentToken = getAccessToken();
      setValidToken(isTokenValid(currentToken));
    }, 1000); // Check validity of token every 1 second

    return () => {
      clearInterval(interval);
    };
  }, []);

  return validToken ? <Outlet /> : <Navigate to="/login" replace />;
}

export function App() {
  const accessToken = useSelector((state) => state.user.user?.accessToken);

  // Read from local storage in a JSON format and store in idMap function in _helpers
  const localTranslationFile = localStorage.getItem("ltf");
  if (localTranslationFile && localTranslationFile.trim() !== "") {
    try {
      const parsedData = JSON.parse(localTranslationFile);
      if (parsedData && Object.keys(parsedData).length > 0) {
        idMap(parsedData);
      }
    } catch (error) {
      console.error("Error parsing the JSON data:", error);
    }
  }

  useEffect(() => {
    logger.info("Access token updated");
  }, [accessToken]);

  const alert = useSelector((state) => state.alert.message && state.alert);

  useEffect(() => {
    if (alert) {
      toast(alert.message, { type: alert.type });
      clearAlert();
    }
  }, [alert]);

  return (
    <>
      <TimePeriodProvider>
        <OrgRegionProvider>
          <BrowserRouter>
            <Routes>
              <Route exact path="/login" element={<Login />} />
              <Route
                exact
                path="/forget-password"
                element={<ForgetPassword />}
              />
              <Route exact path="/new-password" element={<NewPassword />} />
              <Route element={<ProtectedRoute />}>
                <Route exact path="/" element={<Dashboard />} />
                <Route exact path="/dashboard" element={<Dashboard />} />
                <Route exact path="/dashboard/:orgID" element={<Dashboard />} />
                <Route exact path="/sign-up" element={<SignUp />} />
                <Route exact path="/players" element={<PlayersDashboard />} />
                <Route
                  exact
                  path="/player/:playerId"
                  element={<PlayerDetails />}
                />
                <Route
                  exact
                  path="/players/:playerId/cases/:caseId"
                  element={<Cases />}
                />
                <Route
                  exact
                  path="/registrations"
                  element={<Registrations />}
                />
                <Route exact path="/risk-accounts" element={<RiskAccounts />} />
                <Route exact path="/cases" element={<RiskAccounts />} />
                <Route exact path="/admin" element={<Admin />} />
                <Route exact path="/create-rule" element={<CreateRule />} />
                <Route exact path="/data-search" element={<DataSearch />} />
                <Route exact path="/faq" element={<FAQ />} />
                <Route exact path="/reports" element={<Reports />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </OrgRegionProvider>
      </TimePeriodProvider>
      <ToastContainer autoClose={2000} />
    </>
  );
}
