import "./registrationTable.css";

import React, { useContext, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

import { OrgRegionContext, TimePeriodContext } from "../../../_contexts";
import {
  fromTimeParam,
  toCurrency,
  toHumanDate,
  toHumanID,
  toTimeParam,
} from "../../../_helpers";
import { getPlayers } from "../../../_services";
import { useFetchData } from "../../../_services/dataServices";

const columns = [
  {
    id: 1,
    name: "Meta ID",
    selector: (row) => toHumanID(row.MetaID),
    sortable: true,
    sortOn: "metaid",
    minWidth: "1",
    left: true,
    cell: (row) => {
      return (
        <Link to={`/player/${row.OrgPlayerID}`}>{toHumanID(row.MetaID)}</Link>
      );
    },
  },
  {
    id: 2,
    name: "STATE",
    selector: (row) => row.State,
    minWidth: "1",
    left: true,
  },
  {
    id: 3,
    name: "CREATED DATE",
    selector: (row) => toHumanDate(row.Created),
    sortable: true,
    sortOn: "createdDate",
    minWidth: "1",
    left: true,
  },
  {
    id: 4,
    name: "NBR BETS",
    selector: (row) => row.Statistics.TotalSportsBetBetsCount || 0,
    sortable: true,
    sortOn: "bets",
    minWidth: "1",
    left: true,
  },
  {
    id: 5,
    name: "GROSS BALANCE",
    selector: (row) =>
      toCurrency(row.Statistics.TotalSportsBetGrossBalance) || toCurrency(0),
    sortable: true,
    sortOn: "grosswin",
    minWidth: "1",
    left: true,
  },
  {
    id: 6,
    name: "BONUS TURNOVER",
    selector: (row) =>
      toCurrency(row.Statistics.TotalSportsBetBonusTurnOver) || toCurrency(0),
    sortable: true,
    sortOn: "bonusturnover",
    minWidth: "1",
    left: true,
  },
];

export function RegistrationTable({ includeZero, from, to }) {
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("bets");
  const [sortDirection, setSortDirection] = useState("desc");

  const { org, region } = useContext(OrgRegionContext);
  const { timePeriod } = useContext(TimePeriodContext);

  const handleSort = (column, sortDir) => {
    setSortField(column.sortOn);
    setSortDirection(sortDir);
  };

  const now = new Date();
  const fromTime = fromTimeParam(now, timePeriod);
  const toTime = toTimeParam(now);

  const { data, loading } = useFetchData({
    fetchFunction: getPlayers,
    args: {
      order: sortField,
      orderDirection: sortDirection,
      orgID: org?.ID,
      regionID: region?.ID,
      page: currentPage,
      perPage,
      includeZeroBets: includeZero,
      showCaseOpenOnly: false,
      registeredFrom: fromTime,
      registeredTo: toTime,
      statsFrom: fromTime,
      statsTo: toTime,
    },
    dependencies: [
      currentPage,
      perPage,
      sortField,
      sortDirection,
      includeZero,
      org,
      region,
      fromTime,
      toTime,
    ],
    required: [org, region, fromTime, toTime],
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  return (
    <div className="table">
      <DataTable
        columns={columns}
        data={data.results}
        pagination
        paginationServer
        paginationTotalRows={data.total}
        paginationPerPage={perPage}
        paginationRowsPerPageOptions={[50, 100]}
        progressPending={loading}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        onSort={handleSort}
        sortServer
        fixedHeader
        fixedHeaderScrollHeight="80vh"
        persistTableHead
      />
    </div>
  );
}
