import React from "react";

export function AsideRight() {
  return (
    <div className="asideRight">
      <div className="title">
        <h2>Reports (work in progress)</h2>
      </div>
    </div>
  );
}
