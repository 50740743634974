import "./periodDropdown.css";

import React, { useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import { isToday } from "../../_helpers";

const periods = [
  {
    label: "7 days",
    param: "7_days",
    days: "7",
  },
  {
    label: "30 days",
    param: "30_days",
    days: "30",
  },
  {
    label: "90 days",
    param: "90_days",
    days: "90",
  },
];

function formatPeriod(period) {
  if (period === null) {
    return "Select Period";
  }
  if (period !== null) {
    return `${period.label}`;
  }
  return "";
}

const determineFromParam = (currentDate, period) => {
  function isAllPeriod() {
    return period.days === 0;
  }

  if (isAllPeriod()) return "*";

  const fromDate = new Date(currentDate);
  fromDate.setUTCHours(0);
  fromDate.setUTCMinutes(0);
  fromDate.setUTCSeconds(0);
  fromDate.setUTCMilliseconds(0);

  fromDate.setDate(fromDate.getDate() - period.days);
  const fromParam = fromDate.toISOString();
  return fromParam;
};

const determineToParam = (currentDate) => {
  const toDate = new Date(currentDate);
  toDate.setUTCHours(0);
  toDate.setUTCMinutes(0);
  toDate.setUTCSeconds(0);
  toDate.setUTCMilliseconds(0);

  const toParam = toDate.toISOString();
  return toParam;
};

const determinePeriod = (fromParam, toParam) => {
  const ALL_PERIOD_DAYS_DIFFERENCE = 0;

  function isAllPeriod() {
    return fromParam === "*" && isToday(toParam);
  }

  function calculateDaysDifference(from, to) {
    const fromDate = new Date(from);
    const toDate = new Date(to);
    return Math.ceil((toDate - fromDate) / (1000 * 60 * 60 * 24));
  }

  function findPeriodByDays(days) {
    return periods.find((period) => period.days === days) || null;
  }

  const daysDifference = isAllPeriod()
    ? ALL_PERIOD_DAYS_DIFFERENCE
    : calculateDaysDifference(fromParam, toParam);

  const period = findPeriodByDays(daysDifference);
  return period;
};

export function PeriodDropdown({ onPeriodChange }) {
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const navDropdownTitle = formatPeriod(selectedPeriod);
  const navigate = useNavigate();
  const location = useLocation();

  function updateParams(fromParam, toParam, period) {
    const params = new URLSearchParams(location.search);

    // Update URL params
    params.set("from", fromParam);
    params.set("to", toParam);
    if (period !== null) {
      params.set("period", period.days);
    }

    setSelectedPeriod(period);
    navigate(`?${params.toString()}`);
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const currentDate = new Date();
    const defaultPeriod = periods[0];

    let fromParam = params.get("from") || null;
    let toParam = params.get("to") || null;

    if (fromParam == null && toParam == null) {
      // Default to and from params when none provided
      fromParam = determineFromParam(currentDate, defaultPeriod);
      toParam = determineToParam(currentDate);
    }

    if (fromParam == null) {
      // Default from param when to param is present
      fromParam = "*";
    }

    if (toParam == null) {
      // Default to param when from param is present
      toParam = determineToParam(currentDate);
    }

    const period = determinePeriod(fromParam, toParam);

    updateParams(fromParam, toParam, period);
  }, []);

  const handleItemClick = (period) => {
    const currentDate = new Date();
    setSelectedPeriod(period);
    const fromParam = determineFromParam(currentDate, period);
    const toParam = determineToParam(currentDate);
    updateParams(fromParam, toParam, period);
    onPeriodChange(fromParam, toParam);
    document.activeElement.blur();
  };

  return (
    <NavDropdown
      title={navDropdownTitle}
      id="org-dropdown"
      align="end"
      menuVariant="dark"
      className="nav-dropdown"
    >
      {periods.map((period, periodIndex) => (
        <NavDropdown.Item
          key={period.days}
          onClick={() => handleItemClick(period)}
          className={
            selectedPeriod?.days === period.days ? "nav-dropdown-selected" : ""
          }
        >
          {period.label}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
}
