import { alertConstant, orgPlayerCaseConstant } from "../_constants";
import { TOAST_ERROR, TOAST_SUCCESS } from "../_helpers";

export const createOrgPlayerCaseRequest = () => {
  return {
    type: orgPlayerCaseConstant.CREATE_ORG_PLAYER_CASE,
  };
};

export const createOrgPlayerCaseSuccess = (message) => {
  return (dispatch) => {
    dispatch({
      type: orgPlayerCaseConstant.CREATE_ORG_PLAYER_CASE_SUCCESS,
    });
    dispatch({
      type: alertConstant.ALERT,
      payload: {
        message,
        type: TOAST_SUCCESS,
      },
    });
  };
};

export const createOrgPlayerCaseFailure = (message) => {
  return (dispatch) => {
    dispatch({
      type: orgPlayerCaseConstant.CREATE_ORG_PLAYER_CASE_FAILURE,
    });
    dispatch({
      type: alertConstant.ALERT,
      payload: {
        message,
        type: TOAST_ERROR,
      },
    });
  };
};

export const createOrgPlayerCaseNoteRequest = () => {
  return {
    type: orgPlayerCaseConstant.CREATE_ORG_PLAYER_CASE_NOTE,
  };
};

export const closeOrgPlayerCaseRequest = () => {
  return {
    type: orgPlayerCaseConstant.CLOSE_ORG_PLAYER_CASE,
  };
};

export const createOrgPlayerCaseNoteSuccess = (message) => {
  return (dispatch) => {
    dispatch({
      type: orgPlayerCaseConstant.CREATE_ORG_PLAYER_CASE_NOTE_SUCCESS,
    });
    dispatch({
      type: alertConstant.ALERT,
      payload: {
        message,
        type: TOAST_SUCCESS,
      },
    });
  };
};

export const createOrgPlayerCaseNoteFailure = (err) => {
  return (dispatch) => {
    dispatch({
      type: orgPlayerCaseConstant.CREATE_ORG_PLAYER_CASE_NOTE_FAILURE,
      err,
    });
    dispatch({
      type: alertConstant.ALERT,
      payload: {
        message: err,
        type: TOAST_ERROR,
      },
    });
  };
};

export const closeOrgPlayerCaseSuccess = (message) => {
  return (dispatch) => {
    dispatch({
      type: orgPlayerCaseConstant.CLOSE_ORG_PLAYER_CASE_SUCCESS,
    });
    dispatch({
      type: alertConstant.ALERT,
      payload: {
        message,
        type: TOAST_SUCCESS,
      },
    });
  };
};

export const closeOrgPlayerCaseFailure = (err) => {
  return (dispatch) => {
    dispatch({
      type: orgPlayerCaseConstant.CLOSE_ORG_PLAYER_CASE_FAILURE,
      err,
    });
    dispatch({
      type: alertConstant.ALERT,
      payload: {
        message: err,
        type: TOAST_ERROR,
      },
    });
  };
};
