import Papa from "papaparse";
import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

import { idMap } from "../../_helpers";

export default function LocalTranslationFileModal(props) {
  const uploadFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        const fileContent = reader.result;

        // We want to read the CSV data after the header demarcation
        const csvData = fileContent.split("--- END OF HEADER ---")[1].trim();

        // Parse the CSV
        const parsedData = Papa.parse(csvData, {
          header: true, // assumes the CSV has headers
          dynamicTyping: true,
        });

        // Convert the parsed CSV data to a Map for easier lookup
        const dataMap = new Map();
        parsedData.data.forEach((row) => {
          dataMap.set(
            `${row.state.toUpperCase()}_${row.meta_id}`,
            row.organization_player_id
          );
        });

        // Store the file in local storage of the browser
        const mapArray = Array.from(dataMap.entries());
        localStorage.setItem("ltf", JSON.stringify(mapArray));

        // Store this map in state or elsewhere for use in toHumanID
        idMap(dataMap);
      };
    }

    window.location.href = "/admin";
  };

  return (
    <Modal {...props}>
      <Modal.Header>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <Modal.Title>Upload Local Translation CSV File</Modal.Title>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              Select CSV file - format expected: MetaID,OrganizationID
            </Form.Label>
            <Form.Control type="file" id="fileInput" onChange={uploadFile} />
          </Form.Group>
          <Modal.Footer>
            <Button type="submit" variant="primary">
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
