import "./riskAccountsTable.css";

import React, { useContext, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

import { OrgRegionContext, TimePeriodContext } from "../../../_contexts";
import {
  fromTimeParam,
  toCurrency,
  toHumanID,
  toTimeParam,
} from "../../../_helpers";
import { getPlayers } from "../../../_services";
import { useFetchData } from "../../../_services/dataServices";

const getLinkBasedOnScore = (row) => {
  const rgScore = row.RgScore;

  const paddingLeft = "20px";

  // High
  if (rgScore >= 10) {
    return {
      color: "#8B0000",
      emoji: "🔴",
      paddingLeft: "0px",
    };
  }

  // Medium
  if (rgScore >= 6) {
    return {
      color: "#FFA500",
      emoji: "🟠",
      paddingLeft: "0px",
    };
  }

  // Low
  if (rgScore >= 3) {
    return {
      color: "#FFCC00",
      emoji: "🟡",
      paddingLeft: "0px",
    };
  }

  return { paddingLeft };
};

const generateLink = (row) => {
  const { color, emoji, paddingLeft } = getLinkBasedOnScore(row);
  return (
    <div>
      {emoji && (
        <span style={{ display: "inline-block", width: "20px" }}> {emoji}</span>
      )}
      <Link to={`/player/${row.OrgPlayerID}`} style={{ color, paddingLeft }}>
        {toHumanID(row.MetaID)}
      </Link>
    </div>
  );
};

const columns = [
  {
    id: 1,
    name: "META ID",
    selector: (row) => toHumanID(row.MetaID),
    sortable: true,
    sortOn: "metaid",
    minWidth: "1",
    left: true,
    cell: (row) => generateLink(row),
  },
  {
    id: 2,
    name: "RG SCORE",
    selector: (row) =>
      (row.Score.ActiveRgLossesScore || 0) +
      (row.Score.ActiveRgBettingBehaviorScore || 0) +
      (row.Score.ActiveRgEngagementTimeScore || 0) +
      (row.Score.ActiveRgRiskToleranceScore || 0) +
      (row.Score.ActiveRgChasingScore || 0),
    sortable: true,
    sortOn: "rgscore",
    minWidth: "1",
    left: true,
  },
  // {
  //   id: 3,
  //   name: "TOTAL SCORE",
  //   selector: (row) =>
  //     (row.AbuseScore || 0) +
  //     (row.ArbitrageScore || 0) +
  //     (row.SharpBetScore || 0) +
  //     (row.RgScore || 0),
  //   minWidth: "1",
  //   left: true,
  // },
  {
    id: 4,
    name: "TURNOVER",
    selector: (row) =>
      toCurrency(row.Statistics.TotalSportsBetTurnOver) || toCurrency(0),
    sortable: true,
    sortOn: "turnover",
    minWidth: "1",
    left: true,
  },
  {
    id: 5,
    name: "NBR BETS",
    selector: (row) => row.Statistics.TotalSportsBetBetsCount || 0,
    sortable: true,
    sortOn: "bets",
    minWidth: "1",
    left: true,
  },
  {
    id: 6,
    name: "GROSS BALANCE ",
    selector: (row) =>
      toCurrency(row.Statistics.TotalSportsBetGrossBalance) || toCurrency(0),
    sortable: true,
    sortOn: "grosswin",
    minWidth: "1",
    left: true,
  },
];

export function RiskAccountsTable({ riskLevel }) {
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("rgscore");
  const [sortDirection, setSortDirection] = useState("desc");

  const { org, region } = useContext(OrgRegionContext);
  const { timePeriod } = useContext(TimePeriodContext);

  const now = new Date();
  const fromTime = fromTimeParam(now, timePeriod);
  const toTime = toTimeParam(now);

  const { data, loading } = useFetchData({
    fetchFunction: getPlayers,
    args: {
      orgID: org?.ID,
      regionID: region?.ID,
      statsFrom: fromTime,
      statsTo: toTime,
      page: currentPage,
      perPage,
      includeZeroBets: true,
      showCaseOpenOnly: false,
      isFlagged: true,
      order: sortField,
      orderDirection: sortDirection,
      riskLevel,
    },
    dependencies: [
      org,
      region,
      fromTime,
      toTime,
      currentPage,
      perPage,
      sortField,
      sortDirection,
      JSON.stringify(riskLevel), // Do not reload if array is the same
    ],
    required: [org, region, fromTime, toTime],
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const handleSort = (column, sortDir) => {
    setSortField(column.sortOn);
    setSortDirection(sortDir);
  };

  return (
    <div className="playerDashboardTable">
      <DataTable
        columns={columns}
        data={data.results}
        responsive
        // Pagination
        pagination
        paginationServer
        paginationTotalRows={data.total}
        paginationPerPage={perPage}
        paginationRowsPerPageOptions={[50, 100]}
        progressPending={loading}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        onSort={handleSort}
        sortServer
        // Style
        fixedHeader
        fixedHeaderScrollHeight="80vh"
        persistTableHead
      />
    </div>
  );
}
