import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { useBetween } from "use-between";

const useDateRange = () => {
  // Handle date range
  const today = new Date();
  const lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 10);
  const [dateRange, setDateRange] = useState([lastWeek, today]);
  const [startDate, endDate] = dateRange;
  return { dateRange, setDateRange, startDate, endDate };
};

export const useSharedDateRange = () => useBetween(useDateRange);

export function DateSelector() {
  const { setDateRange, startDate, endDate } = useSharedDateRange();
  return (
    <>
      <div className="mx-3">DATES</div>
      <DatePicker
        closeOnScroll
        selectsRange
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
        }}
        id="headerDate"
      />
    </>
  );
}
