import { combineReducers } from "redux";

import alertReducer from "./alertReducer";
import forgetPassReducer from "./forgetPassReducer";
import usersReducer from "./userReducers";

export default combineReducers({
  alert: alertReducer,
  forgetPassword: forgetPassReducer,
  user: usersReducer,
});
