import {
  closeOrgPlayerCaseFailure,
  closeOrgPlayerCaseRequest,
  closeOrgPlayerCaseSuccess,
  createOrgPlayerCaseFailure,
  createOrgPlayerCaseNoteFailure,
  createOrgPlayerCaseNoteRequest,
  createOrgPlayerCaseNoteSuccess,
  createOrgPlayerCaseRequest,
  createOrgPlayerCaseSuccess,
} from "../_actions";
import { getHttpAlertMessage, store } from "../_helpers";
import {
  closeOrgPlayerCase,
  postOrgPlayerCase,
  postOrgPlayerCaseNote,
} from "./fetcher.ts";

export const orgPlayerCaseCreation = async (inputData) => {
  const callApi = () => {
    return async (dispatch) => {
      try {
        dispatch(createOrgPlayerCaseRequest());
        const { caseType, title, orgID, regionID, orgPlayerID } = inputData;
        const requestData = { caseType, title, orgID, regionID, orgPlayerID };
        await postOrgPlayerCase(requestData);
        dispatch(createOrgPlayerCaseSuccess());
      } catch (err) {
        dispatch(createOrgPlayerCaseFailure, getHttpAlertMessage(err));
      }
    };
  };

  await store.dispatch(callApi());
};

export const orgPlayerCaseNoteCreation = async (formData) => {
  const callApi = () => {
    return async (dispatch) => {
      try {
        dispatch(createOrgPlayerCaseNoteRequest());
        const { content, orgID, regionID, orgPlayerID, orgPlayerCaseID } =
          formData;
        const requestData = {
          content,
          orgID,
          regionID,
          orgPlayerID,
          orgPlayerCaseID,
        };
        await postOrgPlayerCaseNote(requestData);
        dispatch(createOrgPlayerCaseNoteSuccess());
      } catch (err) {
        dispatch(createOrgPlayerCaseNoteFailure(getHttpAlertMessage(err)));
      }
    };
  };

  await store.dispatch(callApi());
};

export const orgPlayerCaseClose = async (data) => {
  const callApi = () => {
    return async (dispatch) => {
      try {
        dispatch(closeOrgPlayerCaseRequest());
        const { orgID, orgPlayerID, regionID, orgPlayerCaseID } = data;
        const requestData = {
          orgID,
          orgPlayerID,
          regionID,
          orgPlayerCaseID,
          closed: true,
        };
        await closeOrgPlayerCase(requestData);
        dispatch(closeOrgPlayerCaseSuccess());
      } catch (err) {
        dispatch(closeOrgPlayerCaseFailure(getHttpAlertMessage(err)));
      }
    };
  };

  await store.dispatch(callApi());
};
