export const isToday = (date) => {
  const today = new Date();
  const parsedToday = today.toISOString().split("T")[0];
  const parsedDate = new Date(date).toISOString().split("T")[0];

  return parsedDate === parsedToday;
};

export const areDatesEqual = (date1, date2) => {
  const parsedDate1 = new Date(date1).toISOString();
  const parsedDate2 = new Date(date2).toISOString();

  return parsedDate1 === parsedDate2;
};
