import { areDatesEqual } from "../../_helpers/dateUtils";

function findStat(stats, date) {
  const stat = stats.find((s) => areDatesEqual(s.StatisticDate, date));
  return stat;
}

function findFirstDate(stats) {
  const stat = stats[0];
  if (!stat) return null;
  return new Date(stat.StatisticDate);
}

function emptyStat(date) {
  return {
    ActiveHours: 0,
    CasinoBetsCount: 0,
    CasinoGrossBalance: 0,
    CasinoTurnOver: 0,
    SportsBetBetsCount: 0,
    SportsBetBonusBetsCount: 0,
    SportsBetBonusGrossBalance: 0,
    SportsBetBonusTurnOver: 0,
    SportsBetGrossBalance: 0,
    SportsBetTurnOver: 0,
    StatisticDate: date.toISOString(),
  };
}

export const fillEmptyValues = (from, to, stats) => {
  if (!from || !to) return [];

  const fromDate = from === "*" ? findFirstDate(stats) : new Date(from);
  const toDate = new Date(to);

  const allStats = [];
  const currentDate = new Date(fromDate);

  while (currentDate < toDate) {
    const currentStat =
      findStat(stats, currentDate) || emptyStat(new Date(currentDate));

    allStats.push(currentStat);

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return allStats;
};
