import { Buffer } from "buffer";
import Cookie from "js-cookie";

// set token to cookie
export const setCookie = (name, data) => {
  Cookie.set(name, data, {
    secure: true,
    path: "/",
  });
};

// get token from cookie
export const getCookie = (name) => {
  return Cookie.get(name);
};

// remove token from cookie
export const removeCookie = (name) => {
  Cookie.remove(name, { path: "/" });
};

export const getLocalStorage = (name) => {
  return localStorage.getItem(name);
};

export const setLocalStorage = (name, data) => {
  localStorage.setItem(name, data);
};

export const removeLocalStorage = (name) => {
  localStorage.removeItem(name);
};

export const getAccessToken = () => {
  return getFromCookieOrLocalStorage("accessToken");
};

export const getRefreshToken = () => {
  return getFromCookieOrLocalStorage("refreshToken");
};

export const getFromCookieOrLocalStorage = (name) => {
  const cookieToken = getCookie(name);
  if (cookieToken) return cookieToken;

  // Attempt to retrieve it from localStorage
  return getLocalStorage(name);
};

export const isTokenValid = (token) => {
  if (!token) return false;
  const decodedToken = JSON.parse(Buffer.from(token.split(".")[1], "base64"));
  const exp = decodedToken.exp * 1000;
  const now = Math.floor(Date.now());
  return now < exp;
};

export const setOrg = (org) => {
  const jsonOrg = JSON.stringify(org);
  localStorage.setItem("org", jsonOrg);
};

export const getOrg = () => {
  const result = getLocalStorage("org");
  if (result !== null) return JSON.parse(result);
  return null;
};

export const setUser = (user) => {
  const jsonOrg = JSON.stringify(user);
  localStorage.setItem("user", jsonOrg);
};

export const getUser = () => {
  const result = getLocalStorage("user");
  if (result !== null) return JSON.parse(result);
  return null;
};

export const getSelectedTimePeriod = () => {
  const result = getLocalStorage("selectedTimePeriod");
  if (result !== null) return JSON.parse(result);
  return null;
};

export const setSelectedTimePeriod = (timePeriod) => {
  const jsonPeriod = JSON.stringify(timePeriod);
  localStorage.setItem("selectedTimePeriod", jsonPeriod);
};

export const setSelectedOperator = (org) => {
  const jsonOrg = JSON.stringify(org);
  localStorage.setItem("selectedOperator", jsonOrg);
};

export const getSelectedOperator = () => {
  const result = getLocalStorage("selectedOperator");
  if (result !== null) return JSON.parse(result);
  return null;
};

export const setOperators = (operators) => {
  const jsonOperators = JSON.stringify(operators);
  localStorage.setItem("operators", jsonOperators);
};

export const getOperators = () => {
  const result = getLocalStorage("operators");
  if (result !== null) return JSON.parse(result);
  return null;
};

export const setSelectedOperatorRegion = (region) => {
  const jsonRegion = JSON.stringify(region);
  localStorage.setItem("selectedOperatorRegion", jsonRegion);
};

export const getSelectedOperatorRegion = () => {
  const result = getLocalStorage("selectedOperatorRegion");
  if (result !== null) return JSON.parse(result);
  return null;
};

export const setSelectedOperatorFromOperators = (operators) => {
  function isOperatorAvailable(orgID) {
    return operators.some((operator) => operator.ID === orgID);
  }

  function findOperator(orgID) {
    return operators.find((operator) => operator.ID === orgID);
  }

  let selectedOrg = null;

  // Pick existing selected operator if valid
  const existingSelectedOrg = getSelectedOperator();
  if (
    existingSelectedOrg !== null &&
    isOperatorAvailable(existingSelectedOrg.ID)
  ) {
    selectedOrg = findOperator(existingSelectedOrg.ID);
  } else {
    // Pick first operator from the list
    [selectedOrg] = operators;
  }

  setSelectedOperator(selectedOrg);
  return selectedOrg;
};

export const setSelectedOperatorRegionFromOperator = (selectedOrg) => {
  function isRegionAvailable(regionID) {
    const regions = selectedOrg?.Regions || [];
    return regions.some((region) => region.ID === regionID);
  }

  function findRegion(regionID) {
    const regions = selectedOrg?.Regions || [];
    return regions.find((region) => region.ID === regionID);
  }

  let selectedOperatorRegion = null;

  const existingSelectedOperatorRegion = getSelectedOperatorRegion();

  if (
    // Pick existing selected region if valid
    existingSelectedOperatorRegion !== null &&
    isRegionAvailable(existingSelectedOperatorRegion.ID)
  ) {
    selectedOperatorRegion = findRegion(existingSelectedOperatorRegion.ID);
  } else {
    // Pick first region from the list
    [selectedOperatorRegion] = selectedOrg.Regions;
  }

  setSelectedOperatorRegion(selectedOperatorRegion);
  return selectedOperatorRegion;
};
