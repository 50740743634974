import "./registrations.css";

import { React, useEffect, useState } from "react";
import { Card, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import { RegistrationTable } from "../../_components";

export function AsideRight() {
  const location = useLocation();
  const navigate = useNavigate();

  const [includeZeroActivity, setIncludeZeroActivity] = useState(false);

  const params = new URLSearchParams(location.search);

  useEffect(() => {
    const includeZeroParam = params.get("includeZero") === "true" || false;
    setIncludeZeroActivity(includeZeroParam);
    params.set("includeZero", includeZeroParam);
    navigate(`?${params.toString()}`);
  }, [window.location.search]);

  const handleIncludeZeroActivityToggleChange = (event) => {
    const value = event.target.checked;
    setIncludeZeroActivity(value);
    const ps = new URLSearchParams(location.search);
    ps.set("includeZero", value);
    navigate(`?${ps.toString()}`);
  };

  return (
    <div className="asideRight">
      <div className="title">
        <h2>Registrations</h2>
      </div>
      <div>
        <Form.Group className="d-flex align-items-left">
          <Form.Label>
            Include zero activity registrations&nbsp;&nbsp;
          </Form.Label>
          <Form.Check
            type="switch"
            id="custom-switch"
            label=""
            checked={includeZeroActivity}
            onChange={handleIncludeZeroActivityToggleChange}
          />
        </Form.Group>
      </div>
      <Row>
        <Card className="bigCard">
          <div className="col md-2" />
          <div className="col col-md-6" />
          <div className="mt-2 mb-3">
            <Card className="mb-3">
              <RegistrationTable includeZero={includeZeroActivity} />
            </Card>
          </div>
        </Card>
      </Row>
    </div>
  );
}
